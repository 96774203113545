import { useForm } from 'react-hook-form'
import {
	PageTitle,
	FormInput,
	FileUploader,
	LoadingOverlay,
	FileType,
} from '../../components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import f7WebNetwork from '../../common/api/customApi/f7WebNetwork'
import { useEffect, useRef, useState } from 'react'
import HtmlEditor from '../../components/News/HtmlEditor'
import { useNavigate, useParams } from 'react-router-dom'
import globalTypes from '../../common/helpers/globalTypes'
import helperFunc from '../../common/helpers/helperFunc'
import { withSwal } from 'react-sweetalert2'

type ImageState = {
	fileFrom: number
	file: FileType | string
}
const InsertUpdateTeamMate = withSwal((props: any) => {
	const { swal } = props
	const htmlEditorRef = useRef<any>()
	const htmlContentRef = useRef<any>()

	const [postMainImage, setPostMainImage] = useState<ImageState>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: '',
	})
	const [detailImage, setDetailImage] = useState<ImageState>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: '',
	})
	let { teamId } = useParams()
	const updatedId = parseInt(teamId || '');
	const [loadingVisible, setLoadingVisible] = useState(updatedId>0)
	const [postLoading, setPostLoading] = useState(false)


	const navigate = useNavigate()

	useEffect(() => {
		if (parseInt(teamId || '') > 0) {
			selectTeamInformation()
		}
	}, [teamId])

	const selectTeamInformation = async () => {
		let teamResult = await f7WebNetwork.get('/kurumsal/detail/' + teamId)

		if (teamResult.isSuccess) {
			let singlePost = teamResult.data

			let htmlContent = singlePost.htmlcontent

			htmlContentRef.current = htmlContent

			let postimage = singlePost.imagepath
			if (postimage) {
				setPostMainImage({
					fileFrom: globalTypes.mainImageType.fromRepository,
					file: helperFunc.getTeamMateImageUrl(postimage),
				})
			}
			let detailpostimage = singlePost.detailimagepath
			if (postimage) {
				setDetailImage({
					fileFrom: globalTypes.mainImageType.fromRepository,
					file: helperFunc.getTeamMateImageUrl(detailpostimage),
				})
			}
			const updateState: any = {
				namesurname: singlePost.namesurname,
				speciality: singlePost.speciality,
				phone: singlePost.phone || '',
				email: singlePost.email || '',
				facebooklink: singlePost.facebooklink || '',
				twitterlink: singlePost.twitterlink || '',
				linkedinlink: singlePost.linkedinlink || '',
				instagramlink: singlePost.instagramlink || '',
			}

			for (let key in updateState) {
				const _key: any = key
				if (updateState.hasOwnProperty(key)) {
					setValue(_key, updateState[key])
				}
			}
		} else {
			alert(teamResult.errorMessage)
		}

		setLoadingVisible(false)
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			namesurname: yup.string().required('Adı Soyadı giriniz'),
			speciality: yup.string().required('Uzmanlık alanı giriniz'),
			phone: yup.string(),
			email: yup.string().email(),
			facebooklink: yup.string(),
			twitterlink: yup.string(),
			linkedinlink: yup.string(),
			instagramlink: yup.string(),
			seokeywords: yup.string(),
			shortdescription: yup.string(),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const postNewsConfirm = async (values: any) => {
		setPostLoading(true)
		const html_content = htmlEditorRef.current.getEditorValue() || ''
		const formData = new FormData()

		if (postMainImage.fileFrom == globalTypes.mainImageType.fromUpload && postMainImage.file) {
			formData.append('image', postMainImage.file)
		}
		if (detailImage.fileFrom == globalTypes.mainImageType.fromUpload && postMainImage.file) {
			formData.append('detailimage', detailImage.file)
		}
		let postId = parseInt(teamId ?? '0')

		const userInfo = helperFunc.getUserInformation()

		formData.append('id', postId + '')
		formData.append('namesurname', values.namesurname)
		formData.append('speciality', values.speciality)
		formData.append('phone', values.phone)
		formData.append('email', values.email)
		formData.append('facebooklink', values.facebooklink)
		formData.append('twitterlink', values.twitterlink)
		formData.append('linkedinlink', values.linkedinlink)
		formData.append('instagramlink', values.instagramlink)
		formData.append('seokeywords', values.seokeywords)
		formData.append('shortdescription', values.shortdescription)
		formData.append('htmlcontent', html_content)
		formData.append('UserHash', userInfo.userHash)

		const url =
			postId > 0 ? '/kurumsal/update-team-mate' : '/kurumsal/insert-team-mate'

		const postResult = await f7WebNetwork.postFormData(url, formData)

		setPostLoading(false)
		if (postResult.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text:
					'Avukat başarılı bir şekilde ' +
					(postId > 0 ? 'Güncellendi' : 'Eklendi'),
			})
			navigate('/takim/takimlistesi')
		} else {
			alert(postResult.errorMessage + '')
		}
	}
	const handleFormData = (values: any) => {
		postNewsConfirm(values)
	}
	const habdleActiveStatusSave = (values: any) => {
		postNewsConfirm(values)
	}

	if (loadingVisible) {
		return <LoadingOverlay isVisible={loadingVisible} />
	}

	return (
		<div>
			<PageTitle title="Avukat Ekle" />

			<LoadingOverlay isVisible={postLoading} />
			<form className="valid-form " onSubmit={handleSubmit(handleFormData)}>
				<div className="form-group lg:col-span-2 flex justify-end rounded-sm overflow-hidden mb-3 ">
					<button
						type="button"
						onClick={handleSubmit(habdleActiveStatusSave)}
						className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
					>
						<i className={`mdi mdi-content-save mr-2`} /> Kaydet
					</button>
				</div>

				<div className="card">
					<div className="p-6 pt-4">
						<div className="grid lg:grid-cols-1 mb-5 ">
							<div className="grid lg:grid-cols-1 gap-y-2 ">
								<div className="form-group">
									<FormInput
										name="namesurname"
										type="text"
										label="Adı Soyadı"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="speciality"
										type="text"
										label="Uzmanlık Alanı"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>

								<div className="form-group">
									<FormInput
										name="phone"
										type="text"
										label="Telefon Numarası"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="email"
										type="text"
										label="Email Adresi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="facebooklink"
										type="text"
										label="Facebook Adresi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="twitterlink"
										type="text"
										label="X Adresi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="linkedinlink"
										type="text"
										label="Linkedin Adresi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="instagramlink"
										type="text"
										label="Instagram Adresi"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="seokeywords"
										type="text"
										label="Seo Anahtar Kelimeler"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="shortdescription"
										type="text"
										label="Seo Kısa Açıklama"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
							</div>
						</div>

						<h4 className="card-title mb-6">Avukat Hakkında</h4>
						<HtmlEditor
							ref={htmlEditorRef}
							content={htmlContentRef.current}
							editorfrom="temmate"
						/>

						<div className="mt-5"></div>
					</div>
				</div>
				<div className="card mt-5 p-4">
					<h4 className="card-title mb-6">Fotoğraf</h4>
					<div className="grid lg:grid-cols-1  ml-5 gap-4 mb-5">
						{postMainImage.fileFrom ==
						globalTypes.mainImageType.fromRepository ? (
							<div className="dropzone bg-white flex justify-center items-center dark:bg-gray-800 rounded-md border-2 border-dashed border-gray-200 dark:border-gray-700 min-h-[130px]">
								<img src={postMainImage.file + ''} width={250} height={100} />
								<button
									className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 "
									type="button"
									onClick={() =>
										setPostMainImage({
											file: '',
											fileFrom: -1,
										})
									}
								>
									<i className="mdi mdi-close text-2xl text-danger" />
								</button>
							</div>
						) : (
							<FileUploader
								isSingleFile={true}
								icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
								text="Avukat Fotoğrafı 600x674 Boyutunda."
								onFileUpload={(file) =>
									setPostMainImage({
										file: file[0],
										fileFrom: globalTypes.mainImageType.fromUpload,
									})
								}
							/>
						)}
					</div>
				</div>

				<div className="card mt-5 p-4">
					<h4 className="card-title mb-6">Detay Fotoğrafı</h4>
					<div className="grid lg:grid-cols-1  ml-5 gap-4 mb-5">
						{detailImage.fileFrom ==
						globalTypes.mainImageType.fromRepository ? (
							<div className="dropzone bg-white flex justify-center items-center dark:bg-gray-800 rounded-md border-2 border-dashed border-gray-200 dark:border-gray-700 min-h-[130px]">
								<img src={detailImage.file + ''} width={250} height={100} />
								<button
									className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 "
									type="button"
									onClick={() =>
										setDetailImage({
											file: '',
											fileFrom: -1,
										})
									}
								>
									<i className="mdi mdi-close text-2xl text-danger" />
								</button>
							</div>
						) : (
							<FileUploader
								isSingleFile={true}
								icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
								text="Detay Fotoğrafı 770x477 Boyutunda."
								onFileUpload={(file) =>
									setDetailImage({
										file: file[0],
										fileFrom: globalTypes.mainImageType.fromUpload,
									})
								}
							/>
						)}
					</div>
				</div>
			</form>
		</div>
	)
})

export default InsertUpdateTeamMate
