import f7WebNetwork from '../common/api/customApi/f7WebNetwork'
import helperFunc from '../common/helpers/helperFunc'
import { LoadingOverlay, PageTitle } from '../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { ModalLayout } from 'src/components/HeadlessUI' 

const HomePage = withSwal(() => {
	const navigate = useNavigate()

	const [postList, setPostList] = useState<Array<any>>([])
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)
	const [rowDetailValues, setRowDetailValues] = useState({
		name: '',
		insertdateStr: '',
		email: '',
		phone: '',
		note: '',
	})

	useEffect(() => {
		selectContactRequest()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectContactRequest = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/all-request', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) {
				setPostList(data)
			}
		})
	}

	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[50vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
			<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1 >{rowDetailValues.name} İletişim İsteği</h1>
					</div>
				<div className={` p-4 overflow-y-auto`}>
				
					<div className="flex flex-row justify-between pb-4">
						<label>Adı Soyadı</label>
						<label>{rowDetailValues.name}</label>
					</div>
					<div className="flex flex-row justify-between pb-4">
						<label>Email Adresi</label>
						<label>{rowDetailValues.email}</label>
					</div>
					<div className="flex flex-row justify-between pb-4">
						<label>Telefon Numarası</label>
						<label>{rowDetailValues.phone}</label>
					</div>
					<div className="flex flex-row justify-between pb-4">
						<label>Gönderme Tarihi</label>
						<label>{rowDetailValues.insertdateStr}</label>
					</div>
					<div className="flex flex-col justify-center items-center py-4">
						<label className='font-bold pb-2'>Kullanıcı Notu</label>
						<label>{rowDetailValues.note}</label>
					</div>
				</div>
				<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
					<button
						className="btn bg-light text-gray-800 transition-all"
						onClick={() => setRowDetailVisible(false)}
					>
						Kapat
					</button>
				</div>
			</div>
		</ModalLayout>
	)

	const getActionButtons = (row: any) => {
		return (
			<div className="flex justify-content-between">
				<button
					type="button"
					className="ml-3"
					title="Detayı Gör"
					onClick={() => {
						setRowDetailValues(row)
						setRowDetailVisible(true)
					}}
				>
					<i className="fa fa-eye  text-lg" />
				</button>
			</div>
		)
	}

	return (
		<>
			<PageTitle title="Ana Sayfa" />

 
			<div className="card">
				<div className="p-6">
					<div>
						<LoadingOverlay isVisible={loadingVisible} />
						<div className="flex justify-between w-full mb-4">
							<h4 className="card-title mb-6">İletişim Talebinde Bulunanlar</h4>
						</div>
						<DataTable
							data={postList}
							columns={[
								{
									name: 'Adı Soyadı',
									selector: (row: any) => row.name,
									sortable: true,
								},
								{
									name: 'İstek Tar..',
									// width: '15vw',
									selector: (row: any) => row.insertdateStr,
									sortable: true,
								},
								{
									name: 'Email',
									selector: (row: any) => row.email,
									sortable: true,
									// width: '15vw',
								},
								{
									name: 'Telefon',
									selector: (row: any) => row.phone,
									sortable: true,
								},
								{
									name: 'Açıklama',
									selector: (row: any) => (
										<div
											className="break-all max-w-[250px]"
											title={row.note}
											style={{
												whiteSpace: 'pre',
											}}
										>
											{row.note}
										</div>
									),
									sortable: true,
								},
								{
									name: 'Detay Görüntüle',
									selector: (row) => getActionButtons(row),
									sortable: true,
								},
							]}
							noDataComponent={<div> Herhangi bir kayıt bulunamadı </div>}
							paginationPerPage={50}
							paginationRowsPerPageOptions={[50, 100]}
							fixedHeader
							fixedHeaderScrollHeight={window.innerHeight - 350 + 'px'}
							pagination
							responsive
							selectableRowsHighlight
						/>
					</div>
				</div>
			</div>
			{rowDetailModal()}


		</>
	)
})

export default HomePage
