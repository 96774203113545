const globalTypes = {
	AppServicesAssetsPath: {
		UserImagesPath: 'ProjectImages/UserProfiles/',
		PostImagesPath: 'ProjectImages/PostImages/',
		SagMansetPath: 'ProjectImages/313x331/',
		avatarImages: 'ProjectImages/avatarImages/',
		newsPdfFile: 'ProjectImages/newsdocuments/',
		CommentImagesPath: 'ProjectImages/CommentImages/',
		adsSettingsPath: 'ProjectImages/mobileAdSetting.json',
		photoGalleryPath: 'assets/fotogaleri',
		videoGalleryPath: 'assets/videogaleri',
	},
	webProjectUrl: 'https://avmustafasimsek.com',

	dayList: [
		'Pazar',
		'Pazartesi',
		'Salı',
		'Çarşamba',
		'Perşembe',
		'Cuma',
		'Cumartesi',
	],
	loadingTypes: {
		categoryNews: 1,
		breakingNews: 2,
		homePageSlider: 3,
	},
	loginUserType: {
		superAdmin: 1,
		admin: 2,
		editor: 3,
	},
	htmlBlockType: {
		textInput: 1,
		imageArea: 2,
		embedIframe: 3,
	},
	paginationOptions: {
		// custom: true,
		paginationSize: 5,
		pageStartIndex: 1,
		firstPageText: 'İlk',
		prePageText: 'Geri',
		nextPageText: 'İleri',
		lastPageText: 'Son',
		nextPageTitle: 'İlk',
		prePageTitle: 'Önceki ',
		firstPageTitle: 'Sonraki',
		lastPageTitle: 'Son',
		showTotal: true,
	},

	postSplitTags: {
		textSplitTag: '<!-- finans:paragraph -->',
		imageSplitTag: '<!-- finans:img -->',
		embedHtmlSplitTag: '<!-- finans:social -->',
		imageBottomTextSplitTag: '<!-- finans:img_bottom -->',
	},
	mainImageType: {
		fromRepository: 1,
		fromUrl: 2,
		fromUpload: 3,
		fromBase64: 4,
	},
}

export default globalTypes
