import f7WebNetwork from '../common/api/customApi/f7WebNetwork'
import helperFunc from '../common/helpers/helperFunc'
import { FileType, FileUploader, FormInput, LoadingOverlay, PageTitle } from '../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import DataTable from 'react-data-table-component'
import { ModalLayout } from 'src/components/HeadlessUI'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import globalTypes from 'src/common/helpers/globalTypes'


type ImageState={
	fileFrom: number,
	file: FileType | string,
}

const initialRowDetail = {
	slider_title: '',
	slider_subtitle: '',
	imagepath: '',
	navigateurl: '',
	navigatetext: '',
	id: 0,
};
const HomePageSliderList = withSwal((props: any) => {
	const { swal } = props

	const [postList, setPostList] = useState<Array<any>>([])
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [postLoading, setPostLoading] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)
	const [rowDetailValues, setRowDetailValues] = useState(initialRowDetail)
    const [sliderImage, setSliderImage] = useState<ImageState>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: "",
	}) 

	useEffect(() => {
		selectSliderList()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectSliderList = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/all-slider', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) {
				setPostList(data)
			}
		})
	}

	const insertUpdateSlider = async (values: any) => {
		let isUpdate = rowDetailValues.id > 0
		setPostLoading(true)
		 
		const url = isUpdate ? '/update-slider' : '/add-slider'
	 

        const formData = new FormData();
        if (sliderImage.fileFrom == globalTypes.mainImageType.fromUpload && sliderImage.file) {
			formData.append('image', sliderImage.file); 
		}
        formData.append("id", rowDetailValues.id+"");
		formData.append("slider_title", values.slider_title);
		formData.append("slider_subtitle", values.slider_subtitle);
		formData.append("navigateurl", values.navigateurl);
		formData.append("navigatetext", values.navigatetext);
		formData.append("userHash", userInfo.userHash);

		const result = await f7WebNetwork.postFormData(url, formData)
		if (result.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text:
					'Slider başarılı bir şekilde ' + (isUpdate ? 'Güncellendi' : 'Eklendi'),
			})
			selectSliderList()
			setRowDetailVisible(false)
		} else {
			alert(result.errorMessage)
		}

		setPostLoading(false)
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			slider_title: yup.string().required('Başlık giriniz'),
			slider_subtitle: yup.string(),
			navigateurl: yup.string(),
			navigatetext: yup.string(),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const setFormValue = (row: any) => {
		for (var prop in row) {
			if (!row.hasOwnProperty(prop)) continue

			const fieldName: any = prop
			setValue(fieldName, row[prop])
		}
	}
	const onInsertSlider =()=>{
		setRowDetailValues(initialRowDetail);
		setFormValue(initialRowDetail);
		setRowDetailVisible(true);
	}

	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[50vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<form className="valid-form " onSubmit={handleSubmit(insertUpdateSlider)}>
				<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
					<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1>Slider Ekle/Güncelle</h1>
					</div>
					<div className={` p-4 overflow-y-auto`}>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="slider_title"
									type="text"
									label="Başlık*"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="slider_subtitle"
									type="text"
									label="Alt Başlık"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="navigateurl"
									type="text"
									label="Slider Buton Url'i"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
								<label>
									Slider üzerinde buton gelmesini istiyorsanız butona
									tıkladığında gidilecek adresi yazınız.
								</label>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="navigatetext"
									type="text"
									label="Slider Buton Yazısı"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
                                	<label>
									Butonun üzerine gelecek yazı.
								</label>
							</div>
						</div>
						<div className="card mt-5 p-4">
							<h4 className="card-title mb-6">Fotoğraf</h4>
							<div className="grid lg:grid-cols-1  ml-5 gap-4 mb-5">
								{sliderImage.fileFrom ==
								globalTypes.mainImageType.fromRepository ? (
									<div className="dropzone bg-white flex justify-center items-center dark:bg-gray-800 rounded-md border-2 border-dashed border-gray-200 dark:border-gray-700 min-h-[130px]">
										<img
											src={sliderImage.file + ''}
											width={250}
											height={100}
										/>
										<button
											className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 "
											type="button"
											onClick={() =>
												setSliderImage({
													file: '',
													fileFrom: -1,
												})
											}
										>
											<i className="mdi mdi-close text-2xl text-danger" />
										</button>
									</div>
								) : (
									<FileUploader
										isSingleFile={true}
										icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
										text="Slider Fotoğrafı 1920 × 974 Boyutunda."
										onFileUpload={(file) =>
											setSliderImage({
												file: file[0],
												fileFrom: globalTypes.mainImageType.fromUpload,
											})
										}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
						<button
							type="button"
							onClick={handleSubmit(insertUpdateSlider)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`mdi mdi-content-save mr-2`} />{' '}
							{rowDetailValues.id > 0 ? 'Slider Güncelle' : 'Slider Ekle'}
						</button>
						<button
							type="button"
							className="btn bg-light text-gray-800 transition-all"
							onClick={() => setRowDetailVisible(false)}
						>
							Kapat
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
	)

	const getActionButtons = (row: any) => {
		return (
			<div className="flex justify-content-between">
				<button
					type="button"
					className="ml-3"
					title="Düzenle"
					onClick={() => {
						setFormValue(row)
						setRowDetailValues(row);
                        setSliderImage({
                            file:helperFunc.getSliderImageUrl(row.imagepath),
                            fileFrom:globalTypes.mainImageType.fromRepository
                        });

						setRowDetailVisible(true)
					}}
				>
					<i className="fa fa-edit  text-lg" />
				</button>
			</div>
		)
	}

	return (
		<>
			<PageTitle title="Ana Sayfa Slider Listesi" />

			<div className="form-group lg:col-span-2 flex justify-between rounded-sm overflow-hidden mb-3 ">
				<button
					onClick={onInsertSlider}
					className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
				>
					<i className={`mdi mdi-newspaper-plus mr-2`} /> Slider Ekle
				</button>
			</div>
			<div className="card">
				<div className="p-6">
					<div>
						<LoadingOverlay isVisible={loadingVisible || postLoading} />
						<DataTable
							data={postList}
							columns={[
								{
									name: 'Başlık',
									selector: (row: any) => row.slider_title,
									sortable: true,
								},
								{
									name: 'Alt Başlık',
									selector: (row: any) => row.slider_subtitle,
									sortable: true,
								},
								{
									name: 'Slider Fotoğrafı',
									selector: (row: any) => (
										<div>
											<img src={helperFunc.getSliderImageUrl(row.imagepath)} />
										</div>
									),
									sortable: true,
								},
								{
									name: 'İşlemler',
									selector: (row) => getActionButtons(row),
									sortable: true,
								},
							]}
							noDataComponent={<div> Herhangi bir kayıt bulunamadı </div>}
							paginationPerPage={50}
							paginationRowsPerPageOptions={[50, 100]}
							fixedHeader
							fixedHeaderScrollHeight={window.innerHeight - 350 + 'px'}
							pagination
							responsive
							selectableRowsHighlight
						/>
					</div>
				</div>
			</div>
			{rowDetailModal()}
		</>
	)
})

export default HomePageSliderList
