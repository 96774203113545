import React, { useRef, useState } from 'react';
// import { Editor } from '@tinymce/tinymce-react'; 
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import { BorderSpinner } from '..';
import helperFunc from '../../common/helpers/helperFunc';
import f7WebNetwork from '../../common/api/customApi/f7WebNetwork';



type Props={
    content?: string,
    editorfrom:"blog" |"service" |"temmate" |"aboutus",
    editorheight?:number
}

const HtmlEditor = React.forwardRef(({ content, editorfrom, editorheight }: Props, ref) => {
    // const editorRef = useRef<any>(null);
    const editorRef = useRef<TinyMCEEditor | null>(null);

    const [loadingVisible, setLoadingVisible] = useState(false);

    React.useImperativeHandle(ref, () => ({
        getEditorValue() {

            return editorRef.current?.getContent();
        },
    }));
    const getLoadingPanel = () => {

        if (loadingVisible) {

            return (
                <BorderSpinner ></BorderSpinner>
            );
        } else {
            return null;
        }
    }
    const selector: any = 'textarea#open-source-plugins';
     

    return (
        <>
            <Editor

                onInit={(_evt, editor) => editorRef.current = editor}
                initialValue={content}
                apiKey={"qc66v7hw5hf1q7rlkg8qms16lgd5tesp4zzwymnb02246iya"}

                init={{
                    selector: selector,
                    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap insertdatetime advlist lists wordcount help charmap quickbars emoticons ',
                    editimage_cors_hosts: ['picsum.photos'],
                    menubar: 'edit view insert format tools table help',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist  | insertfile image media link codesample | table | charmap emoticons | fullscreen  preview',
                    toolbar_sticky: true,
                    toolbar_sticky_offset: 108,

                    autosave_ask_before_unload: true,
                    autosave_interval: '30s',
                    autosave_prefix: '{path}{query}-{id}-',
                    autosave_restore_when_empty: false,
                    autosave_retention: '2m',
                    image_advtab: true,
                    importcss_append: true,
                    file_picker_types: "file image media",
                    file_picker_callback: (callback, _, meta) => {
 
                        if (meta.filetype === 'file') {

                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "pdf/*");
                            input.onchange = function (this: any) {
                                var file = this.files[0];

                                var reader: any = new FileReader();
                                reader.onload = function () {

                             
                                    setLoadingVisible(true); 

                                    const formData = new FormData();
                                    formData.append('image', file); 

                                    let url = "";
                                    if (editorfrom =="blog") {
                                        url = "/blog/insert-blog-image";
                                    }else if (editorfrom =="temmate") {
                                        url = "/kurumsal/insert-team-image";
                                    }
                                    else if (editorfrom =="service") {
                                        url = "/hizmetler/insert-blog-image";
                                    }
                                    
                                    else if (editorfrom =="aboutus") {
                                        url = "/kurumsal/insert-about-us-image";
                                    }
                                    f7WebNetwork.postFormData(url, formData).then((result) => {
                                        setLoadingVisible(false);

                                        if (result.isSuccess) {  
                                                var savedFileName = result.data;
                                                let imageUrl = "";
                                                if (editorfrom =="blog") {
                                                    imageUrl = helperFunc.getBlogImageUrl(savedFileName);
                                                }else if (editorfrom =="temmate") {
                                                    imageUrl = helperFunc.getTeamMateImageUrl(savedFileName);
                                                }
                                                else if (editorfrom =="service") {
                                                    imageUrl = helperFunc.getServiceImageUrl(savedFileName);
                                                }
                                                else if (editorfrom =="aboutus") {
                                                    imageUrl = helperFunc.getAboutUsImageUrl(savedFileName);
                                                }
                                            

                                                callback(imageUrl, { title: file.name });
                                             

                                        } else {
                                            alert(result.errorMessage);
                                        }
                                    });





                                };
                                reader.readAsDataURL(file);
                            };
                            input.click();
                        }


                        if (meta.filetype === 'media' || meta.filetype === 'image') {
                            var input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", "image/*");
                            input.onchange = function (this: any) {
                                var file = this.files[0];

                                var reader: any = new FileReader();
                                reader.onload = function () {

                                    var base64 = reader.result.split(",")[1];
                                    callback("data:image/png;base64," + base64, { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };
                            input.click();

                        }
                    },
                    templates: [
                        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                    ],
                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                    height: editorheight || 800,
                    image_caption: true,
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    noneditable_class: 'mceNonEditable',
                    toolbar_mode: 'sliding',
                    contextmenu: 'link image table',
                    skin: 'oxide',
                    content_css: 'default',

                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',

                    // here where it convers from video to object
                    init_instance_callback: function (editor: any) {

                        editor.on('BeforeSetContent', function (e: any) {


                            if (e.content.indexOf("video ") > 0) {
                                if (e.content.indexOf(".pdf") > 0) {
                                    e.content = e.content.replace("<video", "<div");

                                    var width = e.content.match("width=\"(.*)\" height");
                                    var height = e.content.match("height=\"(.*)\" controls");


                                    e.content = e.content.replace("controls=\"controls\"", "");

                                    e.content = e.content.replace("<source", "<object");
                                    e.content = e.content.replace("src=", "data=");

                                    e.content = e.content.replace("</video", "</div");
                                    e.content = e.content.replace("/>", "type=\"application/pdf\" width=\"" + width[1] + "\" height=\"" + height[1] + "\"  ></object>");
                                }

                            }
                        });
                    },


                }}
            />
            {getLoadingPanel()}
        </>
    );
});

export default HtmlEditor;








    // const config = {
    //     readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    //      removeButtons:["eraser", "symbol", "about","spellcheck", "maps","print", "googleMaps", "subscript",
    //             "superscript", "lineHeight", "keyboard", "paste","cut", "copy", "google",
    //             "mode","paint","pageBreak","translate", "InsertClassName"
    //      ],
    //      language:"tr",
    //      uploader: {
    //         url: 'https://xdsoft.net/jodit/finder/?action=fileUpload'
    //     },
    //     filebrowser: {
    //         ajax: {
    //             url: 'https://xdsoft.net/jodit/finder/'
    //         },
    //         height: 580,
    //     }

    // }

    // return (
    //     <JoditEditor
    //     ref={editorRef}
    //     value={content}
    //     config={{
    //         ...config,
    //         height:800, 
    //         filebrowser: {
    //             ajax: {
    //                 url: 'https://xdsoft.net/jodit/finder/',

    //             },
    //             height: 580,
    //         }
    //     }}

    //     onBlur={newContent => setValue(newContent)} // preferred to use only this option to update the content for performance reasons
       
    // />
    // );