export interface MenuItemTypes {
	key: string
	label: string
	isTitle?: boolean
	icon?: string
	url?: string,
	isAdmin?: boolean,
	badge?: {
		variant: string
		text: string
	}
	parentKey?: string
	target?: string
	children?: MenuItemTypes[]
}
const HORIZONTAL_MENU_ITEMS: MenuItemTypes[] = [
	{
		key: 'dashboard',
		icon: 'mdi-view-dashboard',
		label: 'Dashboards',
		isTitle: true,
		children: [
			{
				key: 'dashboard-analytics',
				label: 'Analytics',
				url: '/analytics',
				parentKey: 'dashboard',
			},
		],
	},
	{
		key: 'base-ui',
		label: 'UI Elements',
		isTitle: false,
		icon: 'mdi-invert-colors',
		children: [
			{
				key: 'ui-buttons',
				label: 'Buttons',
				url: '/ui/buttons',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-badges',
				label: 'Badges',
				url: '/ui/badges',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-cards',
				label: 'Cards',
				url: '/ui/cards',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-avatars',
				label: 'Avatars',
				url: '/ui/avatars',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-accordions',
				label: 'Tabs & Accordions',
				url: '/ui/tabs-accordions',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-modals',
				label: 'Modals',
				url: '/ui/modals',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-progress',
				label: 'Progress',
				url: '/ui/progress',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-notification',
				label: 'Notifications',
				url: '/ui/notifications',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-offcanvas',
				label: 'Offcanvas',
				url: '/ui/offcanvas',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-placeholder',
				label: 'Placeholder',
				url: '/ui/placeholder',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-spinners',
				label: 'Spinners',
				url: '/ui/spinners',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-images',
				label: 'Images',
				url: '/ui/images',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-swiper',
				label: 'Swiper',
				url: '/ui/swiper',
				parentKey: 'base-ui',
			},

			{
				key: 'ui-list-group',
				label: 'List Group',
				url: '/ui/list-group',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-embed-video',
				label: 'Embed Video',
				url: '/ui/embed-video',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-dropdowns',
				label: 'Dropdowns',
				url: '/ui/dropdowns',
				parentKey: 'base-ui',
			},
			{
				key: 'ui-tooltips-popover',
				label: 'Tooltips & Popovers',
				url: '/ui/tooltip-popovers',
				parentKey: 'base-ui',
			},

			{
				key: 'ui-typography',
				label: 'Typography',
				url: '/ui/typography',
				parentKey: 'base-ui',
			},
		],
	},
	{
		key: 'apps',
		icon: 'mdi-view-grid-outline',
		label: 'Apps',
		isTitle: true,
		children: [
			{
				key: 'apps-calendar',
				label: 'Calendar',
				url: '/apps/calendar',
				parentKey: 'apps',
			},
			{
				key: 'apps-chat',
				label: 'Chat',
				url: '/apps/chat',
				parentKey: 'apps',
			},
			{
				key: 'apps-email',
				label: 'Email',
				parentKey: 'apps',
				children: [
					{
						key: 'email-inbox',
						label: 'Inbox',
						url: '/apps/email/inbox',
						parentKey: 'apps-email',
					},
					{
						key: 'email-templates',
						label: 'Email Templates',
						url: '/apps/email/templates',
						parentKey: 'apps-email',
					},
				],
			},
			{
				key: 'apps-tasks',
				label: 'Tasks',
				parentKey: 'apps',
				children: [
					{
						key: 'tasks-kanban',
						label: 'Kanban Board',
						url: '/apps/tasks/kanban',
						parentKey: 'apps-tasks',
					},
					{
						key: 'tasks-details',
						label: 'Details',
						url: '/apps/tasks/details',
						parentKey: 'apps-tasks',
					},
				],
			},
			{
				key: 'project',
				label: 'Projects',
				url: '/apps/project',
				parentKey: 'apps',
			},
			{
				key: 'apps-contacts',
				label: 'Contacts',
				parentKey: 'apps',
				children: [
					{
						key: 'contacts-list',
						label: 'Member List',
						url: '/apps/contacts/member-list',
						parentKey: 'apps-contacts',
					},
					{
						key: 'contacts-profile',
						label: 'Profile',
						url: '/apps/contacts/profile',
						parentKey: 'apps-contacts',
					},
				],
			},
		],
	},
	{
		key: 'component',
		label: 'Components',
		icon: 'mdi-lifebuoy',
		isTitle: true,
		children: [
			{
				key: 'widgets',
				label: 'Widgets',
				url: '/widgets',
				parentKey: 'component',
			},
			{
				key: 'extended',
				label: 'Extended UI',
				parentKey: 'component',
				children: [
					{
						key: 'extended-range',
						label: 'Range Slider',
						url: '/extended-ui/range-slider',
						parentKey: 'extended',
					},
					{
						key: 'extended-sweet-alert',
						label: 'Sweet Alert',
						url: '/extended-ui/sweet-alert',
						parentKey: 'extended',
					},
					{
						key: 'extended-draggable',
						label: 'Draggable Cards',
						url: '/extended-ui/draggable-cards',
						parentKey: 'extended',
					},
					{
						key: 'extended-tour-page',
						label: 'Tour Page',
						url: '/extended-ui/tour-page',
						parentKey: 'extended',
					},
					{
						key: 'extended-tree-view',
						label: 'Tree View',
						url: '/extended-ui/tree-view',
						parentKey: 'extended',
					},
				],
			},
			{
				key: 'icons',
				label: 'Icons',
				parentKey: 'component',
				children: [
					{
						key: 'icons-material-design',
						label: 'Material Design',
						url: '/ui/icons/mdi',
						parentKey: 'icons',
					},
					{
						key: 'icons-material-symbols',
						label: 'Material Symbols',
						url: '/ui/icons/mds',
						parentKey: 'icons',
					},
					{
						key: 'icons-lucide',
						label: 'Lucide Icons',
						url: '/ui/icons/lucide-icons',
						parentKey: 'icons',
					},
					{
						key: 'icons-font-awesome',
						label: 'Font Awesome 5',
						url: '/ui/icons/fai',
						parentKey: 'icons',
					},
				],
			},
			{
				key: 'forms',
				label: 'Forms',
				parentKey: 'component',
				children: [
					{
						key: 'forms-basic-elements',
						label: 'General Elements',
						url: '/ui/forms/general-elements',
						parentKey: 'forms',
					},
					{
						key: 'forms-form-advanced',
						label: 'Advanced',
						url: '/ui/forms/advanced',
						parentKey: 'forms',
					},
					{
						key: 'forms-validation',
						label: 'Validation',
						url: '/ui/forms/validation',
						parentKey: 'forms',
					},
					{
						key: 'forms-wizard',
						label: 'Wizard',
						url: '/ui/forms/wizard',
						parentKey: 'forms',
					},
					{
						key: 'forms-Quilljs-editor',
						label: 'Quilljs Editor',
						url: '/ui/forms/Quilljs-editor',
						parentKey: 'forms',
					},
					{
						key: 'forms-pickers',
						label: 'Pickers',
						url: '/ui/forms/pickers',
						parentKey: 'forms',
					},
					{
						key: 'forms-file-uploads',
						label: 'File Uploads',
						url: '/ui/forms/file-uploads',
						parentKey: 'forms',
					},
				],
			},
			{
				key: 'tables',
				label: 'Tables',
				parentKey: 'component',
				children: [
					{
						key: 'tables-basic',
						label: 'Basic Tables',
						url: '/ui/tables/basic-tables',
						parentKey: 'tables',
					},
					{
						key: 'tables-data',
						label: 'Data Tables',
						url: '/ui/tables/data-tables',
						parentKey: 'tables',
					},
				],
			},
			{
				key: 'apex-charts',
				label: 'Apex Charts',
				parentKey: 'component',
				children: [
					{
						key: 'area-apex',
						label: 'Area',
						url: '/ui/apex/area',
						parentKey: 'apex-charts',
					},
					{
						key: 'bar-apex',
						label: 'Bar',
						url: '/ui/apex/bar',
						parentKey: 'apex-charts',
					},
					{
						key: 'bubble-apex',
						label: 'Bubble',
						url: '/ui/apex/bubble',
						parentKey: 'apex-charts',
					},
					{
						key: 'candlestick-apex',
						label: 'Candlestick',
						url: '/ui/apex/candlestick',
						parentKey: 'apex-charts',
					},
					{
						key: 'column-apex',
						label: 'Column',
						url: '/ui/apex/column',
						parentKey: 'apex-charts',
					},
					{
						key: 'heatmap-apex',
						label: 'Heatmap',
						url: '/ui/apex/heatmap',
						parentKey: 'apex-charts',
					},
					{
						key: 'line-apex',
						label: 'Line',
						url: '/ui/apex/line',
						parentKey: 'apex-charts',
					},
					{
						key: 'mixed-apex',
						label: 'Mixed',
						url: '/ui/apex/mixed',
						parentKey: 'apex-charts',
					},
					{
						key: 'timeline-apex',
						label: 'Timeline',
						url: '/ui/apex/timeline',
						parentKey: 'apex-charts',
					},
					{
						key: 'boxplot-apex',
						label: 'Boxplot',
						url: '/ui/apex/boxplot',
						parentKey: 'apex-charts',
					},
					{
						key: 'treemap-apex',
						label: 'Treemap',
						url: '/ui/apex/treemap',
						parentKey: 'apex-charts',
					},
					{
						key: 'pie-apex',
						label: 'Pie',
						url: '/ui/apex/pie',
						parentKey: 'apex-charts',
					},
					{
						key: 'radar-apex',
						label: 'Radar',
						url: '/ui/apex/radar',
						parentKey: 'apex-charts',
					},
					{
						key: 'radialbar-apex',
						label: 'RadialBar',
						url: '/ui/apex/radialbar',
						parentKey: 'apex-charts',
					},
					{
						key: 'scatter-apex',
						label: 'Scatter',
						url: '/ui/apex/scatter',
						parentKey: 'apex-charts',
					},
					{
						key: 'polararea-apex',
						label: 'Polar Area',
						url: '/ui/apex/polararea',
						parentKey: 'apex-charts',
					},
					{
						key: 'sparklines-apex',
						label: 'Sparklines',
						url: '/ui/apex/sparklines',
						parentKey: 'apex-charts',
					},
				],
			},
			{
				key: 'chartjs-charts',
				label: 'ChartJS',
				parentKey: 'component',
				children: [
					{
						key: 'area-chartjs',
						label: 'Area',
						url: '/ui/chartjs/area',
						parentKey: 'chartjs-charts',
					},
					{
						key: 'bar-chartjs',
						label: 'Bar',
						url: '/ui/chartjs/bar',
						parentKey: 'chartjs-charts',
					},
					{
						key: 'line-chartjs',
						label: 'Line',
						url: '/ui/chartjs/line',
						parentKey: 'chartjs-charts',
					},
					{
						key: 'other-chartjs',
						label: 'Other',
						url: '/ui/chartjs/other',
						parentKey: 'chartjs-charts',
					},
				],
			},
			{
				key: 'maps',
				label: 'Maps',
				parentKey: 'component',
				children: [
					{
						key: 'maps-google-maps',
						label: 'Google maps',
						url: '/ui/maps/google-maps',
						parentKey: 'maps',
					},
					{
						key: 'maps-vector-maps',
						label: 'Vector maps',
						url: '/ui/maps/vector-maps',
						parentKey: 'maps',
					},
				],
			},
		],
	},
	{
		key: 'pages',
		icon: 'mdi-cards-outline',
		label: 'Pages',
		isTitle: true,
		children: [
			{
				key: 'pages-auth',
				label: 'Auth Style',
				parentKey: 'pages',
				children: [
					{
						key: 'auth-login',
						label: 'Login',
						url: '/auth/login',
						parentKey: 'pages-auth',
					},
					{
						key: 'auth-register',
						label: 'Register',
						url: '/auth/register',
						parentKey: 'pages-auth',
					},
					{
						key: 'auth-recover-password',
						label: 'Recover Password',
						url: '/auth/recover-password',
						parentKey: 'pages-auth',
					},
					{
						key: 'auth-lock-screen',
						label: 'Lock Screen',
						url: '/auth/lock-screen',
						parentKey: 'pages-auth',
					},
					{
						key: 'auth-confirm-mail',
						label: 'Confirm Mail',
						url: '/auth/confirm-mail',
						parentKey: 'pages-auth',
					},
					{
						key: 'auth-logout',
						label: 'Logout',
						url: '/auth/logout',
						parentKey: 'pages-auth',
					},
				],
			},
			{
				key: 'pages-error',
				label: 'Errors',
				parentKey: 'pages',
				children: [
					{
						key: 'error-404',
						label: 'Error 404',
						url: '/error-404',
						parentKey: 'pages-error',
					},
					{
						key: 'error-500',
						label: 'Error 500',
						url: '/error-500',
						parentKey: 'pages-error',
					},
				],
			},
			{
				key: 'pages-starter',
				label: 'Starter Page',
				url: '/pages/starter',
				parentKey: 'pages',
			},
			{
				key: 'pages-pricing',
				label: 'Pricing',
				url: '/pages/pricing',
				parentKey: 'pages',
			},
			{
				key: 'pages-timeline',
				label: 'Timeline',
				url: '/pages/timeline',
				parentKey: 'pages',
			},
			{
				key: 'pages-invoice',
				label: 'Invoice',
				url: '/pages/invoice',
				parentKey: 'pages',
			},
			{
				key: 'pages-faq',
				label: 'FAQs',
				url: '/pages/faq',
				parentKey: 'pages',
			},
			{
				key: 'pages-gallery',
				label: 'Gallery',
				url: '/pages/gallery',
				parentKey: 'pages',
			},
			{
				key: 'pages-maintenance',
				label: 'Maintenance',
				url: '/pages/maintenance',
				parentKey: 'pages',
			},
			{
				key: 'pages-coming-soon',
				label: 'Coming Soon',
				url: '/pages/coming-soon',
				parentKey: 'pages',
			},
		],
	},
]

const MENU_ITEMS: MenuItemTypes[] = [
	{
		key: 'dashboard',
		label: 'Ana Sayfa İşlemleri',
		isTitle: true,
	},
	{
		key: 'dashboard',
		label: 'Ana Sayfa',
		isTitle: false,
		icon: 'mdi-view-dashboard-outline',
		url: '/admin/ana-sayfa',
	},
	{
		key: 'homepageseo',
		label: 'Ana Sayfa Seo',
		isTitle: false,
		icon: 'mdi-tag',
		url: '/admin/ana-sayfa-seo',
	}, 
	
	{
		key: 'socialaddress',
		label: 'Sosyal Medya',
		isTitle: false,
		icon: 'mdi-link',
		url: '/admin/sosyal-medya',
	},
	{
		key: 'sliderlist',
		label: 'Slider Listesi',
		isTitle: false,
		icon: 'mdi-image-multiple-outline',
		url: '/admin/slider-list',
	},
	{
		key: 'customercomments',
		label: 'Müvekkil Yorumları',
		isTitle: false,
		icon: 'mdi-message-reply-text',
		url: '/admin/muvekkil-yorumlari',
	},
	{
		key: 'apps',
		label: 'Diğer Sayfalar',
		isTitle: true,
	},
	{
		key: 'contactinfo',
		label: 'İletişim Bilgileri',
		isTitle: false,
		icon: 'mdi-contacts-outline',
		url: '/admin/iletisim-bilgileri',
	},
	{
		key: 'aboutus',
		label: 'Hakkımızda',
		isTitle: false,
		icon: 'mdi-information-outline',
		url: '/admin/hakkimizda',
	},
	{
		key: 'privacypolicy',
		label: 'Gizlilik Sözleşmesi',
		isTitle: false,
		icon: 'mdi-shield-lock',
		url: '/admin/gizlilik-sozlesmesi',
	},
	{
		key: 'apps-newslist',
		label: 'Blog',
		isTitle: false,
		icon: 'mdi-newspaper-variant-multiple', 
		children: [
			{
				key: 'apps-news',
				label: 'Blog Listesi',
				isTitle: false,
				icon: 'mdi-newspaper-variant-multiple-outline',
				url: '/blog/bloglistesi',
				parentKey: "apps-newslist"
			},
			{
				key: 'apps_news_insert',
				label: 'Blog Ekle',
				isTitle: false,
				icon: 'mdi-newspaper-plus',
				url: '/blog/blogEkle/0',
				parentKey: "apps-newslist"
			}, 
		],
	},
	{
		key: 'apps-servicelist',
		label: 'Hizmetler',
		isTitle: false,
		icon: 'mdi-account-wrench-outline',
		// url: '/hizmetler/list',
		children: [
			{
				key: 'apps-news',
				label: 'Hizmetler Listesi',
				isTitle: false,
				icon: 'mdi-account-wrench-outline',
				url: '/hizmetler/list',
				parentKey: "apps-servicelist"
			},
			{
				key: 'apps_news_insert',
				label: 'Hizmet Ekle',
				isTitle: false,
				icon: 'mdi-plus',
				url: '/hizmetler/hizmet-detay/0',
				parentKey: "apps-servicelist"
			}, 
		]
	},
	{
		key: 'apps-teammates',
		label: 'Avukatlarımız',
		isTitle: false,
		icon: 'mdi-account-group',
		// url: '/takim/takimlistesi',
		children: [
			{
				key: 'teammateslist',
				label: 'Avukat Listesi',
				isTitle: false,
				icon: 'mdi-text-account',
				url: '/takim/takimlistesi',
				parentKey: "apps-teammates"
			},
			{
				key: 'insertteammates',
				label: 'Avukat Ekle',
				isTitle: false,
				icon: 'mdi-account-plus-outline',
				url: '/takim/takim-ekle/0',
				parentKey: "apps-teammates"
			}, 
		]
	},
	 
	{
		key: 'apps-category',
		label: 'Blog Kategorileri',
		isTitle: false,
		icon: 'mdi-format-list-numbered',
		// url: '/kategori/list',
		children: [
			{
				key: 'category-list',
				label: 'Kategori Listesi',
				url: '/kategori/list',
				parentKey: 'apps-category',
			},
			{
				key: 'category-insert',
				label: 'Kategori Ekle',
				url: '/kategori/ekle/0',
				parentKey: 'apps-category',
			}, 
		
		],
	},  
]
export { MENU_ITEMS, HORIZONTAL_MENU_ITEMS }
