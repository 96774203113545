import Axios from 'axios'
import apiOperations from './apiOperations'
import globalTypes from 'src/common/helpers/globalTypes'

const f7WebNetwork = {
	get: async function (method: string, obj?: any) {
		var queryString = ''
		if (obj) {
			queryString = '/?' + apiOperations.objToQueryString(obj)
		}

		var serviceAddress = globalTypes.webProjectUrl;

		const url = serviceAddress + method + queryString

		try {
			return apiOperations.apiSuccess(await (await Axios.get(url)).data)
		} catch (error) {
			return apiOperations.apiError(error)
		}
	},

	getFromUrl: async function (method: string, obj?: any) {
		var queryString = ''
		if (obj) {
			queryString = '/?' + apiOperations.objToQueryString(obj)
		}

		const url = method + queryString

		try {
			return apiOperations.apiSuccess(await (await Axios.get(url)).data)
		} catch (error) {
			return apiOperations.apiError(error)
		}
	},

	post: async function (method: string, obj: any) {
		const serviceAddress = globalTypes.webProjectUrl
		const url = serviceAddress + method

		try {
			const res = await (
				await Axios.post(url, obj, {
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json; charset=utf-8',
					},
				})
			).data
			var data = apiOperations.apiSuccess(res)
			return data
		} catch (error) { 
			return apiOperations.apiError(error)
		}
	},
	postFormData: async function (method: any, params: any) {
		const url = globalTypes.webProjectUrl + method

		try {
			return await Axios.post(url, params, {
				headers: {
					'Content-Type': 'multipart/form-data',

				},
			})
				.then((response) => {
					return apiOperations.apiSuccess({
						result: {
							data: response,
						},
					})
				})
				.catch((error) => {
					return apiOperations.apiError({
						errorMsg: error,
					})
				})
			// let res = await fetch(url, {
			//   method: "POST",
			//   body: params,
			//   headers: {
			//     'Content-Type': 'multipart/form-data'
			// }
			// });

			// var result2 = await res.text();

			// if (res.ok) {
			//   return apiOperations.apiSuccess({
			//     result: {
			//       data : result2
			//     }
			//   });

			// }else {

			//   return apiOperations.apiError({
			//     errorMsg: result2
			//   });
			// }
		} catch (error) {
			return apiOperations.apiError({
				errorMsg: error,
			})
		}
	},
}

export default f7WebNetwork
