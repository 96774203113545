 
type ComponentProps = {
	categoryList: Array<any> 
}

const CategoryListSelectBox = ({ categoryList }: ComponentProps) => {
	const getCategoryItem = () => {
		return categoryList.map((item: any, index: number) => {
			return (
				<option key={'item.id' + index} value={item.id}>
					{item.categoryname}
				</option>
			)
		})
	}

	return (
		<>
			<option selected disabled>
				Kategori Seç
			</option>
			{getCategoryItem()}
		</>
	)
}
export default CategoryListSelectBox
