import f7WebNetwork from '../common/api/customApi/f7WebNetwork'
import helperFunc from '../common/helpers/helperFunc'
import { FormInput, LoadingOverlay, PageTitle } from '../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import DataTable from 'react-data-table-component'
import { ModalLayout } from 'src/components/HeadlessUI'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

const initialContactInfo = {
	office_name: '',
	address: '',
	email: '',
	phone: '',
	work_phone: '', 
	id: 0,
	map_embed_code:""
}
const ContactInfo = withSwal((props: any) => {
	const { swal } = props

	const [postList, setPostList] = useState<Array<any>>([])
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [postLoading, setPostLoading] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)
	const [rowDetailValues, setRowDetailValues] = useState(initialContactInfo)

	useEffect(() => {
		selectContactRequest()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectContactRequest = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/all-address', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) {
				setPostList(data)
			}
		})
	}

	const insertAddress = async (values: any) => {
		let isUpdate = rowDetailValues.id > 0
		setPostLoading(true)
		if (values.office_name?.length == 0 || values.address?.length == 0) {
			alert('Lütfen zorunlu alanları doldurun.')
			return
		}
		const url = isUpdate ? '/update-contact-info' : '/insert-contact-info'
		const params = {
			...values,
			userHash: userInfo.userHash,
		}

		const result = await f7WebNetwork.post(url, params)
		if (result.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text:
					'Adres başarılı bir şekilde ' + isUpdate ? 'Güncellendi' : 'Eklendi',
			})
			selectContactRequest()
			setRowDetailVisible(false)
		} else {
			alert(result.errorMessage)
		}

		setPostLoading(false)
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			office_name: yup.string().required('Ofis adı giriniz'),
			address: yup.string().required('Ofis adresi giriniz'),
			email: yup.string().email(),
			phone: yup.string(),
			office_phne: yup.string(),
			map_embed_code: yup.string(), 
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const onClickAddContact = () => {
		setRowDetailValues(initialContactInfo);
		setFormValue(initialContactInfo);
		setRowDetailVisible(true)
	}

	const setFormValue = (row: any) => {
		for (var prop in row) {
			if (!row.hasOwnProperty(prop)) continue

			const fieldName: any = prop
			setValue(fieldName, row[prop])
		}
	}

	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[50vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<form className="valid-form " onSubmit={handleSubmit(insertAddress)}>
				<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
					<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1>İletişim Bilgileri</h1>
					</div>
					<div className={` p-4 overflow-y-auto`}>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="office_name"
									type="text"
									label="Ofis Adı*"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="address"
									type="text"
									label="Ofis Adresi*"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="email"
									type="text"
									label="Email Adresi"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="work_phone"
									type="text"
									label="Ofis Telefonu"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="phone"
									type="text"
									label="Cep Telefonu"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div> 
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="map_embed_code"
									type="text"
									label="Google Map Embed Kodu"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div> 

						
					</div>
					<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
						<button
							type="button"
							onClick={handleSubmit(insertAddress)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`mdi mdi-content-save mr-2`} />{' '}
							{rowDetailValues.id > 0 ? 'Adresi Güncelle' : 'Adresi Ekle'}
						</button>
						<button
							type="button"
							className="btn bg-light text-gray-800 transition-all"
							onClick={() => setRowDetailVisible(false)}
						>
							Kapat
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
	)

	const getActionButtons = (row: any) => {
		return (
			<div className="flex justify-content-between">
				<button
					type="button"
					className="ml-3"
					title="Düzenle"
					onClick={() => {
						setFormValue(row)
						setRowDetailValues(row)

						setRowDetailVisible(true)
					}}
				>
					<i className="fa fa-edit  text-lg" />
				</button>
			</div>
		)
	}

	return (
		<>
			<PageTitle title="Adres Listesi" />

			<div className="form-group lg:col-span-2 flex justify-between rounded-sm overflow-hidden mb-3 ">
				<button
					onClick={onClickAddContact}
					className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
				>
					<i className={`mdi mdi-newspaper-plus mr-2`} /> Adres Ekle
				</button>
			</div>
			<div className="card">
				<div className="p-6">
					<div>
						<LoadingOverlay isVisible={loadingVisible || postLoading} />
						<div className="flex justify-between w-full mb-4">
							<h4 className="card-title mb-6">İletişim Talebinde Bulunanlar</h4>
						</div>
						<DataTable
							data={postList}
							columns={[
								{
									name: 'Ofis Adı',
									selector: (row: any) => row.office_name,
									sortable: true,
								},
								{
									name: 'Adres',
									selector: (row: any) => row.address,
									sortable: true,
								},
								{
									name: 'Email',
									selector: (row: any) => row.email,
									sortable: true,
								},
								{
									name: 'Telefon',
									selector: (row: any) => row.phone,
									sortable: true,
								},

								{
									name: 'Ofis Telefonu',
									selector: (row: any) => row.work_phone,
									sortable: true,
								}, 
								{
									name: 'İşlemler',
									selector: (row) => getActionButtons(row),
									sortable: true,
								},
							]}
							noDataComponent={<div> Herhangi bir kayıt bulunamadı </div>}
							paginationPerPage={50}
							paginationRowsPerPageOptions={[50, 100]}
							fixedHeader
							fixedHeaderScrollHeight={window.innerHeight - 350 + 'px'}
							pagination
							responsive
							selectableRowsHighlight
						/>
					</div>
				</div>
			</div>
			{rowDetailModal()}
		</>
	)
})

export default ContactInfo
