import { useForm } from 'react-hook-form'
import {
	PageTitle,
	FormInput,
	FileUploader,
	LoadingOverlay,
	FileType,
} from '../components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import f7WebNetwork from '../common/api/customApi/f7WebNetwork'
import { useEffect, useRef, useState } from 'react'
import HtmlEditor from '../components/News/HtmlEditor'
import { useNavigate, useParams } from 'react-router-dom'
import globalTypes from '../common/helpers/globalTypes'
import helperFunc from '../common/helpers/helperFunc'
import { withSwal } from 'react-sweetalert2'

type ImageState = {
	fileFrom: number
	file: FileType | string
}
const AboutUs = withSwal((props: any) => {
	const { swal } = props
	const htmlEditorRef = useRef<any>()
	const htmlContentRef = useRef<any>()
	const userInfo = helperFunc.getUserInformation()

	const [loadingVisible, setLoadingVisible] = useState(true)
	const [postLoading, setPostLoading] = useState(false)
	const [aboutUsImage, setAboutUsImage] = useState<ImageState>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: '',
	})

	useEffect(() => {
        selectTeamInformation();
	}, [])

	const selectTeamInformation = async () => {
		const params = {
			userHash: userInfo.userHash,
		}
		let aboutResult = await f7WebNetwork.get('/kurumsal/get-about-us/', params)
 
		if (aboutResult.isSuccess && aboutResult.data) {
			let singlePost = aboutResult.data

			let htmlContent = singlePost.htmlcontent

			htmlContentRef.current = htmlContent
			if (singlePost?.imagepath) {
				setAboutUsImage({
					fileFrom: globalTypes.mainImageType.fromRepository,
					file: helperFunc.getAboutUsImageUrl(singlePost?.imagepath),
				})
			}


			const updateState: any = {
				shortdescription: singlePost.shortdescription,
				seokeywords: singlePost.seokeywords,
				id: singlePost.id||0,
				title:singlePost.title
			}

			for (let key in updateState) {
				const _key: any = key
				if (updateState.hasOwnProperty(key)) {
					setValue(_key, updateState[key])
				}
			}
		} else {
			alert(aboutResult.errorMessage)
		}

		setLoadingVisible(false)
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			shortdescription: yup.string().required('Kısa Açıklama Giriniz'),
			seokeywords: yup.string().required('Seo Anahtar Kelimeleri giriniz'), 
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const postNewsConfirm = async (values: any) => {
		setPostLoading(true)
		const html_content = htmlEditorRef.current.getEditorValue() || ''


		const formData = new FormData()

		if (
			aboutUsImage.fileFrom == globalTypes.mainImageType.fromUpload &&
			aboutUsImage.file
		) {
			formData.append('image', aboutUsImage.file)
		}

		formData.append('title', values.title)
		formData.append('htmlcontent', html_content)
		formData.append('userHash', userInfo.userHash)
		formData.append('shortdescription', values.shortdescription)
		formData.append('seokeywords', values.seokeywords) 

		// const params = {
		// 	...values,
		// 	htmlcontent: html_content,
        //     userHash: userInfo.userHash
		// }
 
		const postResult = await f7WebNetwork.postFormData('/kurumsal/update-about-us' , formData)

		setPostLoading(false)
		if (postResult.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text:"Bilgiler başarılı bir şekilde güncellendi"
			}) 
		} else {
			alert(postResult.errorMessage + '')
		}
	}
	const handleFormData = (values: any) => {
		postNewsConfirm(values)
	}
	const habdleActiveStatusSave = (values: any) => {
		postNewsConfirm(values)
	}

	if (loadingVisible) {
		return <LoadingOverlay isVisible={loadingVisible} />
	}

	return (
		<div>
			<PageTitle title="Hakkımızda Sayfası" />

			<LoadingOverlay isVisible={postLoading} />
			<form className="valid-form " onSubmit={handleSubmit(handleFormData)}>
				<div className="form-group lg:col-span-2 flex justify-end rounded-sm overflow-hidden mb-3 ">
					<button
						type="button"
						onClick={handleSubmit(habdleActiveStatusSave)}
						className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
					>
						<i className={`mdi mdi-content-save mr-2`} /> Kaydet
					</button>
				</div>

				<div className="card">
					<div className="p-6 pt-4">
						<div className="grid lg:grid-cols-1 mb-5 ">
							<div className="grid lg:grid-cols-1 gap-y-2 ">
							<div className="form-group">
									<FormInput
										name="title"
										type="text"
										label="Başlık"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="shortdescription"
										type="textarea"
										label="Kısa Açıklama"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										defaultValue={''}
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="seokeywords"
										type="text"
										label="Seo Anahtar Kelimeler"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div> 
                              
							</div>
						</div>
						<div className="card mt-5 p-4">
							<h4 className="card-title mb-6">Hakkımızda Fotoğrafı(Ana sayfada Görünür)</h4>
							<div className="grid lg:grid-cols-1  ml-5 gap-4 mb-5">
								{aboutUsImage.fileFrom ==
								globalTypes.mainImageType.fromRepository ? (
									<div className="dropzone bg-white flex justify-center items-center dark:bg-gray-800 rounded-md border-2 border-dashed border-gray-200 dark:border-gray-700 min-h-[130px]">
										<img
											src={aboutUsImage.file + ''}
											width={250}
											height={100}
										/>
										<button
											className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 "
											type="button"
											onClick={() =>
												setAboutUsImage({
													file: '',
													fileFrom: -1,
												})
											}
										>
											<i className="mdi mdi-close text-2xl text-danger" />
										</button>
									</div>
								) : (
									<FileUploader
										isSingleFile={true}
										icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
										text="Hakkımızda Fotoğrafı En-Boy eşit olmalı"
										onFileUpload={(file) =>
											setAboutUsImage({
												file: file[0],
												fileFrom: globalTypes.mainImageType.fromUpload,
											})
										}
									/>
								)}
							</div>
						</div>

						<h4 className="card-title mb-6">İçerik Yazısı</h4>
						<HtmlEditor
							ref={htmlEditorRef}
							content={htmlContentRef.current}
							editorfrom="temmate"
						/>

						<div className="mt-5"></div>
					</div>
				</div>
			</form>
		</div>
	)
})

export default AboutUs
