import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useRef, useState } from 'react'
import f7WebNetwork from 'src/common/api/customApi/f7WebNetwork'
import globalTypes from 'src/common/helpers/globalTypes'
import helperFunc from 'src/common/helpers/helperFunc'
import {
	FileType,
	FileUploader,
	FormInput,
	LoadingOverlay,
	PageTitle,
} from 'src/components'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { withSwal } from 'react-sweetalert2'
import { ModalLayout } from 'src/components/HeadlessUI'

type ImageState = {
	fileFrom: number
	file: FileType | string
}
const HomePageSeo = withSwal((props: any) => {
	const { swal } = props

	const htmlEditorRef = useRef<any>()
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)
	const [appConstantsValues, setAppConstantsValues] = useState({
		copyrighttext: '',
		homeseokeyword: '',
		homeseodesc: '',
		googlesitever: '',
		yandexsitever: '',
		// hometitle: '',
	})
	const [homePageSeoImage, setHomePageSeoImage] = useState<ImageState>({
		fileFrom: globalTypes.mainImageType.fromUpload,
		file: '',
	})

	useEffect(() => {
		selectContactRequest()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectContactRequest = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/get-app-constants', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) {
				if (data?.seoimagepath) {
					setHomePageSeoImage({
						fileFrom: globalTypes.mainImageType.fromRepository,
						file: helperFunc.getHomeSeoImageUrl(data?.seoimagepath),
					})
				}

				setFormValue(data)
				setAppConstantsValues(data)
			}
		})
	}

	const updateAppConstants = async (values: any) => {
		setLoadingVisible(true)

		const formData = new FormData()

		if (
			homePageSeoImage.fileFrom == globalTypes.mainImageType.fromUpload &&
			homePageSeoImage.file
		) {
			formData.append('seoimage', homePageSeoImage.file)
		}

		formData.append('copyrighttext', values.copyrighttext)
		formData.append('userHash', userInfo.userHash)
		formData.append('homeseokeyword', values.homeseokeyword || '')
		formData.append('homeseodesc', values.homeseodesc || '')
		formData.append('googlesitever', values.googlesitever || '')
		formData.append('yandexsitever', values.yandexsitever || '')
		// formData.append('hometitle', values.hometitle || '')

		const result = await f7WebNetwork.postFormData(
			'/update-homepage-seo',
			formData
		)
		setLoadingVisible(false)
		if (result.isSuccess) {
			setAppConstantsValues(values)

			setRowDetailVisible(false)
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text: 'Sabit yazılar başarılı bir şekilde güncellendi.',
			})
		} else {
			swal.fire({
				title: 'Hata',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'error',
				text: result.errorMessage,
			})
		}
	}
	const setFormValue = (row: any) => {
		for (var prop in row) {
			if (!row.hasOwnProperty(prop)) continue

			const fieldName: any = prop
			setValue(fieldName, row[prop])
		}
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			copyrighttext: yup.string().required('Zorunlu alan'),
			homeseokeyword: yup.string().required('Zorunlu alan'),
			homeseodesc: yup.string().required('Zorunlu alan'),
			googlesitever: yup.string(),
			yandexsitever: yup.string(),
			// hometitle: yup.string().required('Zorunlu alan'),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	console.log(errors)

	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[50vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<form className="valid-form " onSubmit={handleSubmit(updateAppConstants)}>
				<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
					<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1>Ana Sayfa Seo Ekle/Güncelle</h1>
					</div>
					<div className={` p-4 overflow-y-auto`}>
						{/* <div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="hometitle"
									type="text"
									label="Ana Sayfa Başlığı"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div> */}
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="homeseodesc"
									type="text"
									label="Ana Sayfa Seo Açıklaması"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>

						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="homeseokeyword"
									type="text"
									label="Ana Sayfa Seo Anahtar Kelimeler"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>

						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="googlesitever"
									type="text"
									label="Google Site Verification Kodu"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="yandexsitever"
									type="text"
									label="Yandex Site Verification Kodu"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="copyrighttext"
									type="text"
									label="En Altta Görünen Copyright Texti"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>

						<div className="card mt-5 p-4">
							<h4 className="card-title mb-6">Ana Sayfa Seo Fotoğrafı</h4>
							<div className="grid lg:grid-cols-1  ml-5 gap-4 mb-5">
								{homePageSeoImage.fileFrom ==
								globalTypes.mainImageType.fromRepository ? (
									<div className="dropzone bg-white flex justify-center items-center dark:bg-gray-800 rounded-md border-2 border-dashed border-gray-200 dark:border-gray-700 min-h-[130px]">
										<img
											src={homePageSeoImage.file + ''}
											width={250}
											height={100}
										/>
										<button
											className="inline-flex flex-shrink-0 justify-center items-center h-8 w-8 "
											type="button"
											onClick={() =>
												setHomePageSeoImage({
													file: '',
													fileFrom: -1,
												})
											}
										>
											<i className="mdi mdi-close text-2xl text-danger" />
										</button>
									</div>
								) : (
									<FileUploader
										isSingleFile={true}
										icon="mdi mdi-cloud-upload-outline text-4xl text-gray-300 dark:text-gray-200"
										text="Ana Sayfa Seo Fotoğrafı."
										onFileUpload={(file) =>
											setHomePageSeoImage({
												file: file[0],
												fileFrom: globalTypes.mainImageType.fromUpload,
											})
										}
									/>
								)}
							</div>
						</div>
					</div>

					<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
						<button
							type="button"
							onClick={handleSubmit(updateAppConstants)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`mdi mdi-content-save mr-2`} /> Güncelle
						</button>
						<button
							type="button"
							className="btn bg-light text-gray-800 transition-all"
							onClick={() => setRowDetailVisible(false)}
						>
							Kapat
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
	)

	const emptyText = 'Henüz eklenmedi'
	return (
		<div className="card mb-4">
			<PageTitle title="Ana Sayfa Seo" />
			<div className="p-6">
				<div>
					<LoadingOverlay isVisible={loadingVisible} />
					<div className="flex justify-between w-full mb-4">
						<h4 className="card-title mb-6">Site İçi Yazılar</h4>
					</div>

					<div className={` p-4 overflow-y-auto`}>
						{/* <div className="flex flex-col justify-between pb-2 ">
							<h1 className="mr-12  font-bold">Ana Sayfa Başlığı</h1>
							<span className="ml-4 mt-2">
								{appConstantsValues.hometitle || emptyText}
							</span>
						</div> */}
						<div className="flex flex-col justify-between pb-2 ">
							<h1 className="mr-12  font-bold">Ana Sayfa Seo Kısa Açıklama</h1>
							<span className="ml-4 mt-2">
								{appConstantsValues.homeseodesc || emptyText}
							</span>
						</div>
						<div className="flex flex-col justify-between py-2 border-t-[1px] border-dark ">
							<h1 className="mr-12  font-bold">
								Ana Sayfa Seo Anahtar Kelimeler
							</h1>
							<span className="ml-4 mt-2">
								{appConstantsValues.homeseokeyword || emptyText}
							</span>
						</div>

						<div className="flex flex-col justify-between py-2 border-t-[1px] border-dark">
							<h1 className="mr-12  font-bold">
								Google Site Verification Kodu
							</h1>
							<span className="ml-4 mt-2">
								{appConstantsValues.googlesitever || emptyText}
							</span>
						</div>
						<div className="flex flex-col justify-between py-2 border-t-[1px] border-dark">
							<h1 className="mr-12  font-bold">
								Yandex Site Verification Kodu
							</h1>
							<span className={'ml-4 mt-2'}>
								{appConstantsValues.yandexsitever || emptyText}
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className=" text-right pb-4 pr-4">
				<button
					onClick={() => setRowDetailVisible(true)}
					className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
				>
					<i className={`fa fa-edit  text-lg mr-2`} /> Değerleri Güncelle
				</button>
			</div>

			{rowDetailModal()}
		</div>
	)
})
export default HomePageSeo
