import f7WebNetwork from '../../common/api/customApi/f7WebNetwork'
import helperFunc from '../../common/helpers/helperFunc'
import { LoadingOverlay, PageTitle } from '../../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'

const TeamMateList = withSwal((props: any) => {
	const navigate = useNavigate()

	const { swal } = props
	const [postList, setPostList] = useState<Array<any>>([])
	const [loadingVisible, setLoadingVisible] = useState(false)

	useEffect(() => {
		selectTeamList()
	}, [])
	const selectUserInfo = () => {
		let user = helperFunc.getUserInformation()

		return user
	}
	const userInfo: any = selectUserInfo()

	const selectTeamList = () => {
		setLoadingVisible(true)

        let params = {
            userHash:userInfo.userHash
        }
		f7WebNetwork.get('/kurumsal/all', params).then((result) => {
			setLoadingVisible(false)
			let data = result.data || []
		 
			if (result.isSuccess) {
				setPostList(data)
			}
		})
	} 

	const newsMakePassive = (id: number) => {
		if (window.confirm('Kullanıcı kalıcı olarak silinecek. Devam edilsin mi?')) {
			setLoadingVisible(true)

			let params = {
				id: id, 
				UserHash: userInfo.userHash,
			}
			f7WebNetwork.post('/kurumsal/delete-team', params).then((result) => {
				setLoadingVisible(false)
				let data: any = result.data

				if (data.isSuccess) {
					swal.fire({
						title: 'Başarılı',
						confirmButtonText: 'Ok',
						position: 'top-end',
						icon: 'success',
						text: 'Kullanıcı silindi..',
						allowOutsideClick: true,
						timer: 3000,
					})

					selectTeamList();
				} else {
					setLoadingVisible(false)
					alert(data.errorMessage)
				}
			})
		}
	}

 
	const getDeleteButton = (row: any) => {
	 
			return (
				<button
					type="button"
					className=""
					title="Kullanıcıyı Sil"
					onClick={() => newsMakePassive(row.id)}
				>
					<i className="fa fa-trash text-lg text-danger" />
				</button>
			) 
	}

	const geetActionButtons = (row: any) => {
		return (
			<div className="flex justify-content-between">
	

				<button
					type="button"
					className="mr-3"
					title="Blogu Düzenle"
					onClick={() => {
						navigate('/takim/takim-ekle/' + row.id)
					}}
				>
					<i className="fa fa-edit  text-lg" />
				</button>
                {getDeleteButton(row)}
			</div>
		)
	}

	return (
		<>
			<PageTitle title="Avukat Listesi" />

			<div className="form-group lg:col-span-2 flex justify-between rounded-sm overflow-hidden mb-3 ">
				<button
					onClick={() => navigate('/takim/takim-ekle/0')}
					className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
				>
					<i className={`mdi mdi-newspaper-plus mr-2`} /> Avukat Ekle
				</button>
			</div>
			<div className="card">
				<div className="p-6">
					<div>
						<LoadingOverlay isVisible={loadingVisible} />
						<DataTable
							data={postList}
							columns={[
								{
									name: 'id',
									selector: (row: any) => row.id,
									sortable: true,
									width: '100px',
								},
								{
									name: 'Adı Soyadı',
									selector: (row: any) => row.namesurname,
									sortable: true,
									width: '32vw',
								},
								{
									name: 'Uzmanlığı',
									width: '180px',
									selector: (row: any) => row.speciality,
									sortable: true,
								},
								{
									name: 'Telefonu',
									width: '150px',
									selector: (row: any) => row.phone,
									sortable: true,
								},
								{
									name: 'İşlemler',
									width: '180px',
									selector: (row) => geetActionButtons(row),
									sortable: true,
								},
							]}
							noDataComponent={<div> Herhangi bir kayıt bulunamadı </div>}
							paginationPerPage={50}
							paginationRowsPerPageOptions={[50, 100]}
							fixedHeader
							fixedHeaderScrollHeight={window.innerHeight - 350 + 'px'}
							pagination
							responsive
							selectableRowsHighlight
						/>
					</div>
				</div>
			</div>
		</>
	)
})
export default TeamMateList
