import f7WebNetwork from '../../common/api/customApi/f7WebNetwork'
import helperFunc from '../../common/helpers/helperFunc'
import { FormInput, LoadingOverlay, PageTitle } from '../../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { ModalLayout } from 'src/components/HeadlessUI'

const ServiceList = withSwal((props: any) => {
	const navigate = useNavigate()

	const { swal } = props
	const [postList, setPostList] = useState<Array<any>>([])
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)

	const [appConstantsValues, setAppConstantsValues] = useState({
		service_left_desc: '',
		service_top_desc: '',
	})

	useEffect(() => {
		selectServices();
		selectContactRequest();
	}, [])
	const selectUserInfo = () => {
		let user = helperFunc.getUserInformation()

		return user
	}
	const userInfo: any = selectUserInfo()

	const selectServices = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/hizmetler/all', params).then((result) => {
			setLoadingVisible(false)
			let data = result.data || []

			if (result.isSuccess) {
				setPostList(data)
			}
		})
	}


	const selectContactRequest = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/get-app-constants', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) { 
			 

				setFormValue(data)
				setAppConstantsValues(data)
			}
		})
	}
	const updateAppConstants =async(values: any)=>{

		setLoadingVisible(true)

	 
		const params = {
			service_left_desc: values.service_left_desc,
			service_top_desc: values.service_top_desc, 
			userHash: userInfo.userHash,
		}

		const result = await f7WebNetwork.post('/update-service-text', params)
		setLoadingVisible(false)
		if (result.isSuccess) {
			setAppConstantsValues(values)

			setRowDetailVisible(false)
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text: 'Gizlilik sözleşmesi başarılı bir şekilde güncellendi.',
			})
		} else {
			swal.fire({
				title: 'Hata',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'error',
				text: result.errorMessage,
			})
		}
	}


	const newsMakePassive = (postId: number) => {
		if (
			window.confirm('Hizmet kaydı kalıcı olarak silinecek. Devam edilsin mi?')
		) {
			setLoadingVisible(true)

			let params = {
				id: postId,
				Status: false,
				UserHash: userInfo.userHash,
			}
			f7WebNetwork.post('/services/delete', params).then((result) => {
				setLoadingVisible(false)
				let data: any = result.data

				if (data.isSuccess) {
					swal.fire({
						title: 'Başarılı',
						confirmButtonText: 'Ok',
						position: 'top-end',
						icon: 'success',
						text: 'Pasife alındı..',
						allowOutsideClick: true,
						timer: 3000,
					})
					selectServices()
				} else {
					setLoadingVisible(false)
					alert(data.errorMessage)
				}
			})
		}
	}

	const geetActionButtons = (row: any) => {
		return (
			<div className="flex justify-content-between">
				<button
					type="button"
					className=""
					title="Hizmeti Sil"
					onClick={() => newsMakePassive(row.id)}
				>
					<i className="fa fa-check-circle text-lg text-success" />
				</button>

				<button
					type="button"
					className="ml-3"
					title="Hizmeti Düzenle"
					onClick={() => {
						navigate('/hizmetler/hizmet-detay/' + row.id)
					}}
				>
					<i className="fa fa-edit  text-lg" />
				</button>
			</div>
		)
	}

	const setFormValue = (row: any) => {
		for (var prop in row) {
			if (!row.hasOwnProperty(prop)) continue

			const fieldName: any = prop
			setValue(fieldName, row[prop])
		}
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			service_left_desc: yup.string().required('Zorunlu alan'),
			service_top_desc: yup.string().required('Zorunlu alan'),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods
	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[50vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<form className="valid-form " onSubmit={handleSubmit(updateAppConstants)}>
				<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
					<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1>Slider Ekle/Güncelle</h1>
					</div>
					<div className={` p-4 overflow-y-auto`}>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="service_left_desc"
									type="text"
									label="Hizmetler Kısa Açıklama(Seo için ve Ana Sayfa'da görünür)*"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className="pb-4">
							<div className="form-group">
								<FormInput
									name="service_top_desc"
									type="text"
									label="Ana Sayfa Hukuki Hizmet Alanlarımız Bölümünde Alt Açıklama*"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
					</div>

					<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
						<button
							type="button"
							onClick={handleSubmit(updateAppConstants)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`mdi mdi-content-save mr-2`} /> Güncelle
						</button>
						<button
							type="button"
							className="btn bg-light text-gray-800 transition-all"
							onClick={() => setRowDetailVisible(false)}
						>
							Kapat
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
	)

	return (
		<>
			<PageTitle title="Hizmet Listesi" />
			<div className="card mb-4">
				<div className="flex justify-between w-full mb-4">
					<h4 className="card-title ml-4 mt-4">
						Ana Sayfa Hizmetler Bölümündeki Yazılar
					</h4>
				</div>
				<div className="p-6 pt-0">
					<div className="flex flex-col justify-between py-2">
						<h1 className="mr-12  font-bold">Hizmetler Kısa Açıklama(Seo için ve Ana Sayfa'da görünür)</h1>
						<span className="ml-4 mt-2">
							{appConstantsValues.service_left_desc || '--'}
						</span>
					</div>
					<div className="flex flex-col justify-between py-2 border-t-[1px] border-dark">
						<h1 className="mr-12  font-bold">Hizmetler Üst Yazı</h1>
						<span className="ml-4 mt-2">
							{appConstantsValues.service_top_desc || '--'}
						</span>
					</div>

					<div className=" text-right pb-4 pr-4">
						<button
							onClick={() => setRowDetailVisible(true)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`fa fa-edit  text-lg mr-2`} /> Yazıları Güncelle
						</button>
					</div>
				</div>
			</div>

			{rowDetailModal()}
			<div className="form-group lg:col-span-2 flex justify-between rounded-sm overflow-hidden mb-3 ">
				<button
					onClick={() => navigate('/hizmetler/hizmet-detay/0')}
					className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
				>
					<i className={`mdi mdi-newspaper-plus mr-2`} /> Hizmet Ekle
				</button>
			</div>
			<div className="card">
				<div className="p-6">
					<div>
						<LoadingOverlay isVisible={loadingVisible} />
						<DataTable
							data={postList}
							columns={[
								{
									name: 'id',
									selector: (row: any) => row.id,
									sortable: true,
									width: '100px',
								},
								{
									name: 'Başlık',
									selector: (row: any) => row.title,
									sortable: true,
									width: '32vw',
								},
								{
									name: 'Alt Başlık',
									width: '180px',
									selector: (row: any) => row.subtitle,
									sortable: true,
								},
								{
									name: 'url',
									width: '150px',
									selector: (row: any) => row.slug,
									sortable: true,
								},
								{
									name: 'Actions',
									width: '180px',
									selector: (row) => geetActionButtons(row),
									sortable: true,
								},
							]}
							noDataComponent={<div> Herhangi bir kayıt bulunamadı </div>}
							paginationPerPage={50}
							paginationRowsPerPageOptions={[50, 100]}
							fixedHeader
							fixedHeaderScrollHeight={window.innerHeight - 350 + 'px'}
							pagination
							responsive
							selectableRowsHighlight
						/>
					</div>
				</div>
			</div>
		</>
	)
})
export default ServiceList
