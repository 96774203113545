import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

// components
import PrivateRoute from './PrivateRoute' 
import HomePage from 'src/pages/HomePage'
import ContactInfo from 'src/pages/ContactInfo'
import SocialAdress from 'src/pages/SocialAdress'
import ServiceList from 'src/pages/Services/ServiceList'
import InsertUpdateService from 'src/pages/Services/InsertUpdateService'
import HomePageSliderList from 'src/pages/HomePageSliderList'
import InsertUpdateTeamMate from 'src/pages/TeamMates/InsertUpdateTeamMate'
import TeamMateList from 'src/pages/TeamMates/TeamMateList'
import AboutUs from 'src/pages/AboutUs' 
import PrivacyPolicy from 'src/pages/PrivacyPolicy'
import HomePageSeo from 'src/pages/HomePageSeo'
import CustomerComments from 'src/pages/CustomerComments'

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login')) 
const Logout = React.lazy(() => import('../pages/auth/Logout'))
 
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'))
 
// // error
const Error404 = React.lazy(() => import('../pages/error/Error404'))
const Error500 = React.lazy(() => import('../pages/error/Error500'))

const InsertUpdateNews = React.lazy(() => import('../pages/news/InsertUpdateNews'))
const NewsList = React.lazy(() => import('../pages/news/NewsList'))  
const CategoryList = React.lazy(() => import('../pages/category/CategoryList'))  
const InsertUpdateCategory = React.lazy(() => import('../pages/category/InsertUpdateCategory')) 


export interface RoutesProps {
	path: RouteProps['path']
	name?: string
	element?: RouteProps['element']
	route?: any
	exact?: boolean
	icon?: string
	header?: string
	roles?: string[]
	children?: RoutesProps[]
}

// dashboards
const dashboardRoutes: RoutesProps = {
	path: '/',
	name: 'Dashboards',
	icon: 'home',
	header: 'Navigation',
	element: <HomePage />,
	children: [
		{
			path: '/admin/ana-sayfa',
			name: 'HomePage',
			element: <HomePage />,
			route: PrivateRoute,
		},
		{
			path: '/admin/ana-sayfa-seo',
			name: 'HomePage',
			element: <HomePageSeo />,
			route: PrivateRoute,
		}, 
		{
			path: '/admin/iletisim-bilgileri',
			name: 'ContactInfo',
			element: <ContactInfo />,
			route: PrivateRoute,
		},
		{
			path: '/admin/sosyal-medya',
			name: 'SocialAdress',
			element: <SocialAdress />,
			route: PrivateRoute,
		},
		{
			path: '/admin/slider-list',
			name: 'Sliders',
			element: <HomePageSliderList />,
			route: PrivateRoute,
		},
		{
			path: '/admin/muvekkil-yorumlari',
			name: 'CustomerComments',
			element: <CustomerComments />,
			route: PrivateRoute,
		},
		{
			path: '/admin/hakkimizda',
			name: 'AboutUs',
			element: <AboutUs />,
			route: PrivateRoute,
		},
		{
			path: '/admin/gizlilik-sozlesmesi',
			name: 'PrivacyPolicy',
			element: <PrivacyPolicy />,
			route: PrivateRoute,
		},
	],
}

const newsRoutes: RoutesProps = {
	path: '/haber',
	name: 'News',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/blog/bloglistesi',
			name: 'NewsList',
			element: <NewsList />,
			route: PrivateRoute,
		},
		{
			path: '/blog/blogEkle/:haberId/',
			name: 'InsertUpdateNews',
			element: <InsertUpdateNews />,
			route: PrivateRoute,
		}, 
	],
} 
const servicesRoutes: RoutesProps = {
	path: '/hizmetler',
	name: 'Services',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/hizmetler/list',
			name: 'ServiceList',
			element: <ServiceList />,
			route: PrivateRoute,
		},
		{
			path: '/hizmetler/hizmet-detay/:serviceId/',
			name: 'InsertUpdateService',
			element: <InsertUpdateService />,
			route: PrivateRoute,
		}, 
	],
} 
const categoryRoutes: RoutesProps = {
	path: '/kategori',
	name: 'kategori',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/kategori/list',
			name: 'CategoryList',
			element: <CategoryList />,
			route: PrivateRoute,
		}, 
		{
			path: '/kategori/ekle/:categoryId',
			name: 'InsertUpdateCategory',
			element: <InsertUpdateCategory />,
			route: PrivateRoute,
		},
		
	],
}

const teamsRoutes: RoutesProps = {
	path: '/takim',
	name: 'Teams',
	icon: 'home',
	header: 'Navigation',
	children: [
		{
			path: '/takim/takimlistesi',
			name: 'TeamMateList',
			element: <TeamMateList />,
			route: PrivateRoute,
		},
		{
			path: '/takim/takim-ekle/:teamId/',
			name: 'InsertUpdateTeamMate',
			element: <InsertUpdateTeamMate />,
			route: PrivateRoute,
		}, 
	],
} 
 

// auth
const authRoutes: RoutesProps[] = [
	{
		path: '/auth/login',
		name: 'Login',
		element: <Login />,
		route: Route,
	}, 
	{
		path: '/auth/logout',
		name: 'Logout',
		element: <Logout />,
		route: Route,
	}, 
	{
		path: '/auth/lock-screen',
		name: 'Lock Screen',
		element: <LockScreen />,
		route: Route,
	}, 
]

// public routes
const otherPublicRoutes = [
	{
		path: '*',
		name: 'Error - 404',
		element: <Error404 />,
		route: Route,
	},
	{
		path: 'pages/error-404',
		name: 'Error - 404',
		element: <Error404 />,
		route: Route,
	},
	{
		path: 'pages/error-500',
		name: 'Error - 500',
		element: <Error500 />,
		route: Route,
	}, 
]

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
	let flatRoutes: RoutesProps[] = []

	routes = routes || []
	routes.forEach((item: RoutesProps) => {
		flatRoutes.push(item)
		if (typeof item.children !== 'undefined') {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
		}
	})
	return flatRoutes
}

// All routes
const authProtectedRoutes = [
	dashboardRoutes,
	newsRoutes, 
	servicesRoutes,
	teamsRoutes,
	categoryRoutes,
	...otherPublicRoutes 
]
const publicRoutes = [...authRoutes]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])
export {
	publicRoutes,
	authProtectedRoutes,
	authProtectedFlattenRoutes,
	publicProtectedFlattenRoutes,
}

