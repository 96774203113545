import f7WebNetwork from '../common/api/customApi/f7WebNetwork'
import helperFunc from '../common/helpers/helperFunc'
import { FormInput, LoadingOverlay, PageTitle } from '../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

const SocialAdress = withSwal((props: any) => {
	const { swal } = props
 
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [postLoading, setPostLoading] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)

	useEffect(() => {
		selectContactRequest()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectContactRequest = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/social/default-socials', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess && data) {
				setFormValue(data)
			}
		})
	}

	const updateSocialAddress = async (values: any) => {
		setPostLoading(true)
	 
		const url = '/social/update-default-socials'
		const params = {
			...values,
			userHash: userInfo.userHash,
		}

		const result = await f7WebNetwork.post(url, params)
		if (result.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text: 'Adres başarılı bir şekilde Güncellendi',
			}) 
			setRowDetailVisible(false)
		} else {
			alert(result.errorMessage)
		} 
		setPostLoading(false)
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			facebooklink: yup.string(),
			twitterlink: yup.string(),
			linkedinlink: yup.string(),
			instagramlink: yup.string(),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const setFormValue = (row: any) => {
		for (var prop in row) {
			if (!row.hasOwnProperty(prop)) continue

			const fieldName: any = prop
			setValue(fieldName, row[prop])
		}
	}

	return (
		<>
			<PageTitle title="Sosyal Medya Adresleri" />
            <LoadingOverlay isVisible={postLoading} />

			<form className="valid-form " onSubmit={handleSubmit(updateSocialAddress)}>
				<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
					<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1>Sosyal Medya Adresleri</h1>
					</div>
					<div className={` p-4 overflow-y-auto`}>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="facebooklink"
									type="text"
									label="Facebook Adresi"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="twitterlink"
									type="text"
									label="Twitter Adresi"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="linkedinlink"
									type="text"
									label="Linkedin Adresi"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="instagramlink"
									type="text"
									label="Instagram Adresi"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
					 
					</div>
					<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
						<button
							type="button"
							onClick={handleSubmit(updateSocialAddress)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`mdi mdi-content-save mr-2`} /> Adresleri Güncelle
						</button>
					</div>
				</div>
			</form>
		</>
	)
})

export default SocialAdress
