import { useEffect, useRef, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import { ModalLayout } from 'src/components/HeadlessUI'
import helperFunc from 'src/common/helpers/helperFunc'
import f7WebNetwork from 'src/common/api/customApi/f7WebNetwork'
import { LoadingOverlay, PageTitle } from 'src/components'
import HtmlEditor from 'src/components/News/HtmlEditor'

const PrivacyPolicy = withSwal((props: any) => {
	const { swal } = props

	const htmlEditorRef = useRef<any>()
	const htmlContentRef = useRef<any>()
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)
	const [appConstantsValues, setAppConstantsValues] = useState({
		privacy_policy: '',
	})

	useEffect(() => {
		selectContactRequest()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectContactRequest = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/get-app-constants', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) {
				htmlContentRef.current = data?.privacy_policy

				setAppConstantsValues(data)
			}
		})
	}

	const updateAppConstants = async (values: any) => {
		setLoadingVisible(true)

		const html_content = htmlEditorRef.current.getEditorValue() || ''

		const params = {
			PrivacyText: html_content,
			userHash: userInfo.userHash,
		}

		const result = await f7WebNetwork.post('/update-privacy-policy', params)
		setLoadingVisible(false)
		if (result.isSuccess) {
			setAppConstantsValues(values)

			setRowDetailVisible(false)
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text: 'Gizlilik sözleşmesi başarılı bir şekilde güncellendi.',
			})
		} else {
			swal.fire({
				title: 'Hata',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'error',
				text: result.errorMessage,
			})
		}
	}

	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[70vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
				<div className="w-full p-4 mb-5 text-xl font-bold">
					<h1>Gizlilik Sözleşmesi Ekle/Güncelle</h1>
				</div>
				<div className={` p-4 overflow-y-auto`}>
					<h4 className="card-title mb-6">Gizlilik Sözleşmesi</h4>
					{rowDetailVisible && (
						<HtmlEditor
							ref={htmlEditorRef}
							content={htmlContentRef.current}
							editorfrom="temmate"
							editorheight={800}
						/>
					)}
				</div>

				<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
					<button
						type="button"
						onClick={updateAppConstants}
						className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
					>
						<i className={`mdi mdi-content-save mr-2`} /> Güncelle
					</button>
					<button
						type="button"
						className="btn bg-light text-gray-800 transition-all"
						onClick={() => setRowDetailVisible(false)}
					>
						Kapat
					</button>
				</div>
			</div>
		</ModalLayout>
	)

	return (
		<div className="card mb-4">
            <PageTitle title='Gizlilik Sözleşmesi' />
			<div className="p-6">
				<div>
					<LoadingOverlay isVisible={loadingVisible} />

					<div className=" text-right pb-4 pr-4">
						<button
							onClick={() => setRowDetailVisible(true)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`fa fa-edit  text-lg mr-2`} /> Yazıyı Güncelle
						</button>
					</div>

					<div className={` p-4 overflow-y-auto`}>
						<div className="flex flex-col justify-between py-2 ">
							<div
								className="ml-12  mt-2   "
								dangerouslySetInnerHTML={{
									__html: appConstantsValues.privacy_policy,
								}}
							></div>
						</div>
					</div>
				</div>
			</div>

			{rowDetailModal()}
		</div>
	)
})

export default PrivacyPolicy
