import { useForm } from 'react-hook-form'
import {
	PageTitle,
	FormInput,
	FileUploader,
	CategoryListSelectBox,
	LoadingOverlay,
	FileType,
} from '../../components'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import f7WebNetwork from '../../common/api/customApi/f7WebNetwork'
import { useEffect, useRef, useState } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import HtmlEditor from '../../components/News/HtmlEditor'
import { useNavigate, useParams } from 'react-router-dom'
import globalTypes from '../../common/helpers/globalTypes'
import helperFunc from '../../common/helpers/helperFunc'
import { withSwal } from 'react-sweetalert2'

type ImageState = {
	fileFrom: number
	file: FileType | string
}
const InsertUpdateService = withSwal((props: any) => {
	const { swal } = props
	const htmlEditorRef = useRef<any>()
	const htmlContentRef = useRef<any>()
	let { serviceId } = useParams()
	const updatedId = parseInt(serviceId || '');
	const [loadingVisible, setLoadingVisible] = useState(updatedId>0)


	const navigate = useNavigate()

	useEffect(() => {
		if (parseInt(serviceId || '') > 0) {
			selectPostInformation()
		}
	}, [serviceId])

	const selectPostInformation = async () => {
		let blogResult = await f7WebNetwork.get('/hizmetler/detail/' + serviceId)


		if (blogResult.isSuccess) {
			let singlePost = blogResult.data

			let htmlContent = singlePost.servicecontent
            console.log(singlePost);

			htmlContentRef.current = htmlContent

			const updateState: any = { 
				title: singlePost.title,
				slug: singlePost.slug || '',
				subtitle: singlePost.subtitle || '',
				seo_keywords: singlePost.seo_keywords || '',
				seo_desc: singlePost.seo_desc || '',
			}

			for (let key in updateState) {
				const _key: any = key
				if (updateState.hasOwnProperty(key)) {
					setValue(_key, updateState[key])
				}
			}
		} else {
			alert(blogResult.errorMessage)
		}

        setLoadingVisible(false);
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			title: yup.string().required('Blog Başlığı giriniz'),
			slug: yup.string().required('Hizmet Url i giriniz'),
			subtitle: yup.string().required('Alt Başlığı giriniz'),

			seo_keywords: yup.string().required('Seo Anahtar Kelimeleri giriniz'),
			seo_desc: yup.string().required('Seo Açıklaması giriniz'),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const postNewsConfirm = async (values: any) => {
		setLoadingVisible(true)
		const html_content = htmlEditorRef.current.getEditorValue() || ''

		let postId = parseInt(serviceId ?? '0')

		if (
			values.title?.trim().length == 0 ||
			values.subtitle?.length == 0 ||
			html_content?.length == 0 ||
			values.slug?.length == 0 ||
			values.seo_keywords?.length == 0 ||
			values.seo_desc?.length == 0
		) {
			alert('Tüm alanları doldurun')
			return
		}

		const userInfo = helperFunc.getUserInformation()

		const params = {
			id: postId,
			title: values.title,
			slug: values.slug,
			subtitle: values.subtitle,
			servicecontent: html_content,
			seo_keywords: values.seo_keywords,
			seo_desc: values.seo_desc,
			userHash: userInfo.userHash,
		}

		const url = postId > 0 ? '/hizmetler/update-service' : '/hizmetler/add-service'

		const postResult = await f7WebNetwork.post(url, params)

		setLoadingVisible(false)
		if (postResult.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text: 'Haber başarılı bir şekilde eklendi..',
			})
			navigate('/hizmetler/list')
		} else {
			alert(postResult.errorMessage + '')
		}
	}
	const handleFormData = (values: any) => {
		postNewsConfirm(values)
	}
	const habdleActiveStatusSave = (values: any) => {
		postNewsConfirm(values)
	}

    if (loadingVisible) {
        return 	<LoadingOverlay isVisible={loadingVisible} />;
    }
	return (
		<div>
			<PageTitle title="Hizmet Ekle" />

			<LoadingOverlay isVisible={loadingVisible} />
			<form className="valid-form " onSubmit={handleSubmit(handleFormData)}>
				<div className="form-group lg:col-span-2 flex justify-end rounded-sm overflow-hidden mb-3 ">
					<button
						type="button"
						onClick={handleSubmit(habdleActiveStatusSave)}
						className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
					>
						<i className={`mdi mdi-content-save mr-2`} /> Yayınla
					</button>
				</div>

				<div className="card">
					<div className="p-6 pt-4">
						<div className="grid lg:grid-cols-1 mb-5 ">
							<div className="grid lg:grid-cols-1 gap-y-2 ">
								<div className="form-group">
									<FormInput
										name="title"
										type="text"
										label="Hizmet Başlığı"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="subtitle"
										type="text"
										label="Hizmet Alt Başlığı"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="slug"
										type="text"
										label="Hizmet Url'i"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
								<div className="form-group">
									<FormInput
										name="seo_keywords"
										type="text"
										label="Seo Anahtar Kelimeler"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
									<label>Anahtar kelimeleri "," ile ayırarak yazınız</label>
								</div>
								<div className="form-group">
									<FormInput
										name="seo_desc"
										type="text"
										label="Seo Açıklaması"
										labelClassName="text-gray-800 block mb-2"
										className="form-input"
										errors={errors}
										control={control}
										register={register}
									/>
								</div>
							</div>
						</div>
						<label className={'mb-2'}>
                            Hizmet İçeriği
                            </label>
						<HtmlEditor
							ref={htmlEditorRef}
							content={htmlContentRef.current}
							editorfrom='service'
						/>

						<div className="mt-5"></div>
					</div>
				</div>
			</form>
		</div>
	)
})

export default InsertUpdateService
