import { useThemeContext } from '../common'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

type PageTitleProps = {
	title: string
}
const PageTitle = ({ title }: PageTitleProps) => {
	const { updatePageTitle } = useThemeContext()

	useEffect(() => {
		updatePageTitle(title)
	}, [])

	return (
		<Helmet>
			<title> {title} | Mustafa Şimşek Admin</title>
		</Helmet>
	)
}

export default PageTitle
