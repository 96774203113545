import f7WebNetwork from '../common/api/customApi/f7WebNetwork'
import helperFunc from '../common/helpers/helperFunc'
import { FormInput, LoadingOverlay, PageTitle } from '../components'
import { useEffect, useState } from 'react'
import { withSwal } from 'react-sweetalert2'
import DataTable from 'react-data-table-component'
import { ModalLayout } from 'src/components/HeadlessUI'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'

const initialRowDetail = {
	customername: '',
	customertitle: '',
	commenttext: '',
	id: 0,
}
const CustomerComments = withSwal((props: any) => {
	const { swal } = props

	const [postList, setPostList] = useState<Array<any>>([])
	const [loadingVisible, setLoadingVisible] = useState(false)
	const [postLoading, setPostLoading] = useState(false)
	const [rowDetailVisible, setRowDetailVisible] = useState(false)
	const [rowDetailValues, setRowDetailValues] = useState(initialRowDetail)

	useEffect(() => {
		selectCommentList()
	}, [])

	const userInfo: any = helperFunc.getUserInformation()

	const selectCommentList = () => {
		setLoadingVisible(true)
		let params = {
			userHash: userInfo.userHash,
		}
		f7WebNetwork.get('/all-comment', params).then((result) => {
			setLoadingVisible(false)

			let data = result.data || []

			if (result.isSuccess) {
				setPostList(data)
			}
		})
	}

	const deleteComment = async (id: number) => {
		if (window.confirm('Yorum kalıcı olarak silinecek. Devam edilsin mi?')) {
			setPostLoading(true)

			const params = {
				userHash: userInfo.userHash,
				id: id,
			}

			const result = await f7WebNetwork.post('/delete-comment', params)
			if (result.isSuccess) {
				swal.fire({
					title: 'Başarılı',
					confirmButtonText: 'Ok',
					position: 'top-end',
					icon: 'success',
					text: 'Yorum başarılı bir şekilde silindi',
				})
				selectCommentList()
			} else {
				alert(result.errorMessage)
			}

			setPostLoading(false)
		}
	}

	const insertUpdateComment = async (values: any) => {
		const isUpdate = rowDetailValues.id > 0
		setPostLoading(true)

		const url = isUpdate ? '/update-comment' : '/add-comment'

		const params = {
			...values,
			userHash: userInfo.userHash,
			id: rowDetailValues.id,
		}

		const result = await f7WebNetwork.post(url, params)
		if (result.isSuccess) {
			swal.fire({
				title: 'Başarılı',
				confirmButtonText: 'Ok',
				position: 'top-end',
				icon: 'success',
				text:
					'Yorum başarılı bir şekilde ' +
					(isUpdate ? 'Güncellendi' : 'Eklendi'),
			})
			selectCommentList()
			setRowDetailVisible(false)
		} else {
			alert(result.errorMessage)
		}

		setPostLoading(false)
	}

	const schemaResolver = yupResolver(
		yup.object().shape({
			customername: yup.string().required('Müvekkil Adı giriniz'),
			customertitle: yup.string().required('Müvekkil Ünvanı giriniz'),
			commenttext: yup.string().required('Yorum Yazısını giriniz'),
		})
	)

	const methods = useForm({ resolver: schemaResolver })
	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		setValue,
	} = methods

	const setFormValue = (row: any) => {
		for (var prop in row) {
			if (!row.hasOwnProperty(prop)) continue

			const fieldName: any = prop
			setValue(fieldName, row[prop])
		}
	}
	const onInsertComment = () => {
		setRowDetailValues(initialRowDetail)
		setFormValue(initialRowDetail)
		setRowDetailVisible(true)
	}

	const rowDetailModal = () => (
		<ModalLayout
			showModal={rowDetailVisible}
			toggleModal={() => setRowDetailVisible(false)}
			panelClassName={'w-[50vw]'}
			placement=" justify-center items-start"
			isStatic
		>
			<form
				className="valid-form "
				onSubmit={handleSubmit(insertUpdateComment)}
			>
				<div className="duration-300 ease-in-out transition-all m-3 sm:mx-auto flex flex-col bg-white shadow-sm rounded dark:bg-gray-800">
					<div className="w-full p-4 mb-5 text-xl font-bold">
						<h1>Yorum Ekle/Güncelle</h1>
					</div>
					<div className={` p-4 overflow-y-auto`}>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="customername"
									type="text"
									label="Müvekkil Adı*"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="customertitle"
									type="text"
									label="Müvekkil Ünvanı"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
						<div className=" pb-4">
							<div className="form-group">
								<FormInput
									name="commenttext"
									type="textarea"
									label="Yorum Yazısı"
									labelClassName="text-gray-800 block mb-2"
									className="form-input"
									errors={errors}
									control={control}
									register={register}
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-end items-center gap-2 p-4 border-t dark:border-slate-700">
						<button
							type="button"
							onClick={handleSubmit(insertUpdateComment)}
							className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
						>
							<i className={`mdi mdi-content-save mr-2`} />{' '}
							{rowDetailValues.id > 0 ? 'Adresi Güncelle' : 'Adresi Ekle'}
						</button>
						<button
							type="button"
							className="btn bg-light text-gray-800 transition-all"
							onClick={() => setRowDetailVisible(false)}
						>
							Kapat
						</button>
					</div>
				</div>
			</form>
		</ModalLayout>
	)

	const getActionButtons = (row: any) => {
		return (
			<div className="flex justify-content-between">
				<button
					type="button"
					className="ml-3"
					title="Düzenle"
					onClick={() => {
						setFormValue(row)
						setRowDetailValues(row)

						setRowDetailVisible(true)
					}}
				>
					<i className="fa fa-edit  text-lg" />
				</button>

				<button
					type="button"
					className="ml-3 btn"
					title="Yorumu sil"
					onClick={() => {
						deleteComment(row.id)
					}}
				>
					<i className="fa fa-trash-can text-lg text-danger" />
				</button>
			</div>
		)
	}

	return (
		<>
			<PageTitle title="Ana Sayfa Slider Listesi" />

			<div className="form-group lg:col-span-2 flex justify-between rounded-sm overflow-hidden mb-3 ">
				<button
					onClick={onInsertComment}
					className={`btn bg-primary text-white rounded-md px-3 relative after:content-[''] after:absolute after:inset-0 after:bg-black/10`}
				>
					<i className={`mdi mdi-newspaper-plus mr-2`} /> Yorum Ekle
				</button>
			</div>
			<div className="card">
				<div className="p-6">
					<div>
						<LoadingOverlay isVisible={loadingVisible || postLoading} />
						<DataTable
							data={postList}
							columns={[
								{
									name: 'Müvekkil Adı',
									selector: (row: any) => row.customername,
									sortable: true,
								},
								{
									name: 'Ünvanı',
									selector: (row: any) => row.customertitle,
									sortable: true,
								},
								{
									name: 'Yorum',
									selector: (row: any) => row.commenttext,
									sortable: true,
								},
								{
									name: 'İşlemler',
									selector: (row) => getActionButtons(row),
									sortable: true,
								},
							]}
							noDataComponent={<div> Herhangi bir kayıt bulunamadı </div>}
							paginationPerPage={50}
							paginationRowsPerPageOptions={[50, 100]}
							fixedHeader
							fixedHeaderScrollHeight={window.innerHeight - 350 + 'px'}
							pagination
							responsive
							selectableRowsHighlight
						/>
					</div>
				</div>
			</div>
			{rowDetailModal()}
		</>
	)
})

export default CustomerComments
